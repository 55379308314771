import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "monthOnly",
})
export class GetMonthPipe implements PipeTransform {

  transform(date: any, args?: any): any {

    let monthDate = date.split("-")[1];
    let month = "";

    switch (monthDate) {
      case "01":
        month = "Januar";
        break;
      case "02":
        month = "Februar";
        break;
      case "03":
        month = "Mart";
        break;
      case "04":
        month = "April";
        break;
      case "05":
        month = "Maj";
        break;
      case "06":
        month = "Jun";
        break;
      case "07":
        month = "Jul";
        break;
      case "08":
        month = "Avgust";
        break;
      case "09":
        month = "Septembar";
        break;
      case "10":
        month = "Oktobar";
        break;
      case "11":
        month = "Novembar";
        break;
      case "12":
        month = "Decembar";
        break;
    }

    return month;
  }
}
