
//Koristi se za lokalni server

export const environment = {
  production: false,
  clientId: 'Plativoo.Mobile',
  redirectURL: 'https://plativooapp.dewebeloper.com/signin-callback',
  scope: 'openid profile email offline_access PlativooAPI provider user subscription bill',
  userInfoEndpoint: '/connect/userinfo',
  endSessionRedirectUri : 'https://plativooapp.dewebeloper.com/signout-callback',

  AUTH_URL:'https://plativooids.dewebeloper.com',
  API_URL:'https://plativooapi.dewebeloper.com',
  IMAGE_HELPER_ROOT:'assets/img/subscription-helper/',
  ADMIN_PANEL_URL:'https://plativooadmin.dewebeloper.com',
  IS_PAYMENT_ENABLED:true
};
