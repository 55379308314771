import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbDatepickerI18n, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';

//Created
import { CustomDateParserFormatter, I18n, CustomDatepickerI18n } from '../../functions/datapicker-adapter';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {  

  paymentDate;
  paymentDateFormated;
  isMobile: boolean;
  @Output() pickedDateOutput = new EventEmitter();

  constructor(private deviceService: DeviceDetectorService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }

  dateChanged(date){
    this.pickedDateOutput.emit(date);
    if (this.isMobile === true){
      this.paymentDateFormated = date.day +'.'+ date.month +'.'+ date.year
      this.modalService.dismissAll();
    }
  }

  openCalendarModal(content) {
    this.modalService.open(content, {centered: true}).result.then((result) => {      
      console.log('closed');
    }, (reason) => {
      console.log('dissmised');
    });
  }



}
