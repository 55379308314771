
export enum ToastType {
  Default = "default",
  Info = "info",
  Success = "success",
  Wait = "wait",
  Error = "error",
  Warning = "warning"
}


export enum ViewBagOrigin{
  PaymentConfirmation = 'PaymentConfirmation',
  DocumentDetails = 'DocumentDetails'
}
