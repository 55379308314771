import { Directive, Input, ViewContainerRef, TemplateRef } from "@angular/core";
import { Subscription } from "rxjs";
//import { AuthService } from "../services/auth.service";
//import { PermissionValues } from "../models/permission.model";

//https://blog.ng-book.com/managing-user-permissions-in-angular-using-akita/
@Directive({
  selector: "[hasPermission]"
})
export class HasPermissionDirective {
  @Input("hasPermission")
  //checkPermissions: PermissionValues | PermissionValues[];
  private subscription: Subscription;

  constructor(
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<any>,
    //private authService: AuthService
  ) {}

  ngOnInit() {

    // this.authService
    //   .hasPermission(this.checkPermissions)
    //   .subscribe(hasPermission => {
    //     //console.log(this.checkPermissions);
    //     //ovo je poseban slučaj, ako korisnik nema ni jednu rolu vraćamo true
    //     if (Array.isArray(this.checkPermissions) && this.checkPermissions.length == 0 && this.authService.userPermissions.length == 0)
    //     {
    //       this.vcr.clear();
    //       this.vcr.createEmbeddedView(this.tpl);
    //       return;
    //     }

    //     this.vcr.clear();
    //     if (hasPermission) {
    //       this.vcr.createEmbeddedView(this.tpl);
    //     }
    //   });
  }

  ngOnDestroy() {
    //this.subscription && this.subscription.unsubscribe();
  }
}
