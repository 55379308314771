import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

//Created
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  isAuthorized: boolean;
  isExpanded = false;
  currentUser;
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  isMobile;

  constructor(private authService: AuthService,
              private deviceService: DeviceDetectorService ) {
  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.currentUser = this.authService.getCurrentUser();
  }

  ngOnDestroy(): void {

  }

  public onLogoutButtonClick() {
    this.authService.signout();
  }


  onLinkClicked(){
    if(this.isMobile){
      this.body.classList.remove('sidebar-open');
      this.body.classList.add('sidebar-collapse');
    }
}

}
