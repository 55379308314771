import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//Created
import { DocumentService } from '../../services/document.service';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private documentService: DocumentService) { }

  invoiceId;
  subscribers;

  async ngOnInit() {
    this.invoiceId = this.route.snapshot.paramMap.get("documentid");
    await this.getDocumentSubscribers()
  }

  async getDocumentSubscribers(){
    await this.documentService
    .getDocumentSubscribers(this.invoiceId)
    .then(async (receivers) => {     
      this.subscribers = receivers.filter(a => a.contractOwner == false);
    });
  } 


}
