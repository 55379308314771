import { Component, OnInit, Input } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

//Created
import { DocumentHeader, Status } from "../../models/document-header";
import { DocumentHandlerService } from "src/app/services/document-handler.service";

@Component({
  selector: "[document-type-invoice]",
  templateUrl: "./document-type-invoice.component.html",
  styleUrls: ["./document-type-invoice.component.scss"],
})
export class DocumentTypeInvoiceComponent implements OnInit {
  @Input() document: DocumentHeader;
  public isMobile: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    private documentHandler: DocumentHandlerService
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();

    let doc = this.documentHandler.selectedDocuments.filter(
      (d) => d.id == this.document.id
    )[0];

    if (doc !== undefined) {
      this.document["selected"] = doc["selected"];
    }
  }

  onSelectionCgnage(value: boolean, document: DocumentHeader) {
    //console.log(value);

    if (value === true) {
      this.documentHandler.addSelectedDocument(document);
    } else {
      this.documentHandler.removeSelectedDocument(document);
    }

    //console.log(this.documentHandler.selectedDocuments);
  }

  getCompanyTypeIcon(group: Status) {
    let iconPath = "";

    switch (group.code) {
      case 1:
        iconPath = "assets/img/icons/company-type/water-drop.png";
        break;

      case 2:
        break;
      case 4:
        iconPath = "assets/img/icons/company-type/water-drop.png";
        break;

      default:
        break;
    }

    return iconPath;
  }
}
