import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { ToastaService, ToastOptions, ToastData, ToastaEventType, ToastaEvent } from 'ngx-toasta';
import { Subscription } from 'rxjs';
import { ToastType } from '../core/enums';


//https://github.com/emonney/ngx-toasta
@Injectable({
  providedIn: 'root'
})
export class ToastService implements OnInit, OnDestroy {

  private insertedToasts: number[] = [];
  private subscription: Subscription;

  constructor(private toastaService: ToastaService) { }

  themes = [{
    name: 'Default Theme',
    code: 'default'
  }, {
    name: 'Material Design',
    code: 'material'
  }, {
    name: 'Bootstrap',
    code: 'bootstrap'
  }];

  types = [{
    name: 'Default',
    code: 'default',
  }, {
    name: 'Info',
    code: 'info'
  }, {
    name: 'Success',
    code: 'success'
  }, {
    name: 'Wait',
    code: 'wait'
  }, {
    name: 'Error',
    code: 'error'
  }, {
    name: 'Warning',
    code: 'warning'
  }];

  positions = [{
    name: 'Top Left',
    code: 'top-left',
  }, {
    name: 'Top Center',
    code: 'top-center',
  }, {
    name: 'Top Right',
    code: 'top-right',
  }, {
    name: 'Top Full Width',
    code: 'top-fullwidth',
  }, {
    name: 'Bottom Left',
    code: 'bottom-left',
  }, {
    name: 'Bottom Center',
    code: 'bottom-center',
  }, {
    name: 'Bottom Right',
    code: 'bottom-right',
  }, {
    name: 'Bottom Full Width',
    code: 'bottom-fullwidth',
  }, {
    name: 'Center Center',
    code: 'center-center',
  }];

  options = {
    title: 'Toast It!',
    msg: 'Mmmm, tasties...',
    showClose: true,
    showDuration: false,
    timeout: 5000,
    theme: this.themes[0].code,
    type: this.types[0].code
  };


  ngOnInit() {
    //   this.toastaService.events.subscribe((event: ToastaEvent) => {
    //     if (event.type === ToastaEventType.ADD) {
    //       const toast: ToastData = event.value;
    //       this.insertedToasts.push(toast.id);
    //     } else if (event.type === ToastaEventType.CLEAR_ALL) {
    //       this.insertedToasts = [];
    //     }
    //   });
  }

  ngOnDestroy() {
    //   this.subscription.unsubscribe();
  }

  newToast(title: string, message: string, type: ToastType) {
    const toastOptions: ToastOptions = {
      title: title,
      msg: message,
      showClose: this.options.showClose,
      showDuration: this.options.showDuration,
      timeout: this.options.timeout,
      theme: this.options.theme,
      // position: this.options.position,
      onAdd: (toast: ToastData) => {
        console.log('Toast ' + toast.id + ' has been added!');
      },
      onRemove(toast: ToastData) {
        console.log('Toast ' + toast.id + ' has been removed!');
      }
    };

    switch (type) {
      case 'default': this.toastaService.default(toastOptions); break;
      case 'info': this.toastaService.info(toastOptions); break;
      case 'success': this.toastaService.success(toastOptions); break;
      case 'wait': this.toastaService.wait(toastOptions); break;
      case 'error': this.toastaService.error(toastOptions); break;
      case 'warning': this.toastaService.warning(toastOptions); break;
    }
  }
}
