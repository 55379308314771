import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor( 
  @Inject("API_URL") private apiUrl: string,
  private http: HttpClient) { }
   

 public getPdf(documentId) {

  const httpOptions = {
    responseType: 'blob' as 'json',    
  };
  return this.http.get(`${this.apiUrl}/pdf/invoice/${documentId}`, httpOptions);
}


}