export interface HttpParamJson {
  name: string;
  value: string;
  text: string;
  showInList: boolean;
}

export class HttpParam {
  value: string;
  text: string;
  name: string;
  showInList: boolean;

  constructor(param: HttpParamJson) {
    this.name = param.name;
    this.value = param.value;
    this.text = param.text;
    this.showInList = param.showInList;
  }
}
