import { ContractOwner } from "./contract-owner";

export class DocumentHeader {
  constructor(body: DocumentHeader) {
    this.id = body.id;
    this.invoiceId = body.invoiceId;
    this.invoiceDate = body.invoiceDate;
    this.providerName = body.provider.name;
    this.arrivalDate = body.arrivalDate;
    this.title = body.title;
    this.totalAmount = body.totalAmount;
    this.currency = body.currency;
    this.providerId = body.provider.id;
    this.documentType = body.documentType; //Tim dokumenta 'documentType' - Invoice,Messaage..
    this.tags = body.tags;
    if (body.payment != undefined) {
      this.payment = new DocumentPayment(body.payment);
    } else {
      this.payment = null;
    }

    this.friendlyProviderName = body.provider.urlFriendlyName;
    this.shared = body.shared;

    if (body.provider != undefined) {
      this.provider = new Provider(body.provider);
    } else {
      this.provider = null;
    }
    this.contractNumber = body.contractNumber;

    if (body.contractOwner != undefined) {
      this.contractOwner = new ContractOwner(body.contractOwner);
    } else {
      this.contractOwner = null;
    }
  }

  id: string;
  invoiceId: string;
  date: string;
  invoiceDate: string;
  providerName: string;
  arrivalDate: string;
  title: string;
  totalAmount: number;
  currency: string;
  providerId: string;
  documentType: string;
  payment: DocumentPayment;
  friendlyProviderName: string;
  shared: Shared[];
  provider: Provider;
  tags: string;
  contractNumber: string;
  contractOwner: ContractOwner;
}

export class Shared {
  ime: string;
}

export class Provider {
  constructor(body: Provider) {
    this.logoPath = body.logoPath;
    this.name = body.name;
    this.id = body.id;
    this.city = body.city;
    this.urlFriendlyName = body.urlFriendlyName;
    this.contractLabelName = body.contractLabelName;
    this.group = body.group;
  }
  city: string;
  id: string;
  logoPath: string;
  name: string;
  urlFriendlyName: string;
  contractLabelName: string;
  group: Status;
}

export class DocumentPayment {
  constructor(body: DocumentPayment) {
    this.paymentDate = body.paymentDate;
    this.status = body.status;
    this.message = body.message;
  }
  paymentDate: string;
  status: number;
  message: string;
}

export class Status {
  constructor(status: Status) {
    this.code = status.code;
    this.description = status.description;
  }

  code: number;
  description: string;
}
