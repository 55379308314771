import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';

//Created
import { ToastService } from '../../services/toast.service';
import { ToastType } from '../../core/enums';
import { DocumentService } from '../../services/document.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppTranslationService } from '../../services/app-translation.service';
import { formatToISOString } from '../../functions/format-to-locale-iso-string';

@Component({
    selector: 'app-mark-as-paid',
    templateUrl: './mark-as-paid.component.html',   
    styleUrls: ['./mark-as-paid.component.scss']
})

export class MarkAsPaidComponent implements OnInit {

    markAsPaidForm: FormGroup;
    submitted = false;
    documentId: any;
    isMobile: boolean;

    imageError: string;
    isImageSaved: boolean;
    cardImageBase64: string;
    paymentDate: any;

    constructor(
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private documentService: DocumentService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private translationService: AppTranslationService,
        private location: Location) { }

    gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

    pickedDate($event){
        this.paymentDate = $event;
    }

    ngOnInit() {

        this.markAsPaidForm = this.formBuilder.group({
            note: ['', Validators.required],
            transactionId: ['', Validators.required],
        });

        this.isMobile = this.deviceService.isMobile();

        this.activatedRoute.params
            .subscribe(
                (params: Params) => {
                    this.documentId = params['documentid'];
                }
            );
    }

    get f() { return this.markAsPaidForm.controls; }


    fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
            const max_height = 15200;
            const max_width = 25600;

            if (fileInput.target.files[0].size > max_size) {

                this.imageError = this.gT('markAsPaidPage.imageError1') + max_size / 1000 + 'Mb';
                this.toastService.newToast(this.gT('markAsPaidPage.error'), this.imageError, ToastType.Error);
                return false;
            }

            if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
                this.imageError = this.gT('markAsPaidPage.imageError2');
                this.toastService.newToast(this.gT('markAsPaidPage.error'), this.imageError, ToastType.Error);
                return false;
            }

            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    if (img_height > max_height && img_width > max_width) {
                        this.imageError = this.gT('markAsPaidPage.imageError3') + max_height + 'x' + max_width + 'px';
                        this.toastService.newToast(this.gT('markAsPaidPage.error'), this.imageError, ToastType.Error);
                        return false;
                    } else {

                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        // this.previewImagePath = imgBase64Path;
                    }
                };
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    async onSubmit() {
        this.submitted = true;

        if (this.markAsPaidForm.invalid) {
            return;
        }

        let date = null;
        if (this.paymentDate !== undefined){                                 
            date = formatToISOString(this.paymentDate);            
        }
       
        let img = '';
        if ((this.cardImageBase64 !== undefined) && (this.cardImageBase64 !== null)){
            img = this.cardImageBase64.split(',')[1];
        }

        try {
            let formRawValues = this.markAsPaidForm.getRawValue();
            console.log(this.paymentDate +','+this.cardImageBase64);

            let data = {
                invoiceId: this.documentId,
                transactionId: formRawValues.transactionId,
                note: formRawValues.note,
                dateOfPayment: date,
                image :{
                    imgData: img
                }
            }
            console.log(data);
                       
            await this.documentService.markDocumentAsPaid(data);            
            this.router.navigateByUrl('/document/' + this.documentId);
        }
        catch (error) {
            this.toastService.newToast(this.gT('markAsPaidPage.error'), this.gT('markAsPaidPage.savingError'), ToastType.Error);
        }

    }

    removeImage() {
        this.cardImageBase64 = null;
        this.isImageSaved = false;
    }

    cancel() {
        this.location.back();
    }
}
