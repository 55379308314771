import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'app-delete-sub-modal',
  templateUrl: './delete-sub-modal.component.html',
  styleUrls: ['./delete-sub-modal.component.scss']
})
export class DeleteSubModalComponent implements OnInit {

  @Input() id;
  closeResult: string;
  loading;

  constructor(private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private subscriptionService: SubscriptionService) {}

  ngOnInit() {
  }

  async deleteSubscription(){

    this.loading = true;
    try {     
      let result = await this.subscriptionService.deleteSubscription(this.id);     
      this.activeModal.close(true);
    }
    catch (error) {      
      this.activeModal.close(error);
    }
    this.loading = false;
  }

}
