import { Injectable, Inject, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ProvidersService {

  baseUrl = `${this.apiUrl}/provider`; 
  providersList = [];
  municipalityList = [];
 
  constructor(
    @Inject("API_URL") private apiUrl: string,
    private http: HttpClient
  ) {}

  public async GetProvidersData(municipalityId, searchString, forceReaload): Promise<any> {
   
    if (forceReaload === false) {
      if (this.providersList.length > 0) {

        return new Promise<any>((resolve, reject) => {
          resolve(this.providersList);
        });

      } else {
        return await this.GetProviders(municipalityId, searchString);
      }
    }else{
      return await this.GetProviders(municipalityId, searchString);
    }
  }

  public async GetMunicipalityData(forceReaload): Promise<any> {


    if (forceReaload === false) {
      if (this.municipalityList.length > 0) {

        return new Promise<any>((resolve, reject) => {
          resolve(this.municipalityList);
        });

      } else {
        return await this.GetMunicipalities();
      }
    }else{
      return await this.GetMunicipalities();
    }
  }

  public async GetProviders(municipalityId, searchString): Promise<any> {
    let params = new HttpParams()
      .set("municipalityId", municipalityId)
      .set("searchString", searchString);

    let data = await this.http.get<any>(this.baseUrl, { params: params }).toPromise();
    this.providersList = data;

    return new Promise<any>((resolve, reject) => {
      resolve(data);
    });
  }

  public async GetMunicipalities(): Promise<any> {
    const url = `${this.baseUrl}/municipalities`;
    
    let data = await this.http.get<any>(url).toPromise();
    this.municipalityList = data;

    return new Promise<any>((resolve, reject) => {
      resolve(data);
    });
  }

  public async AddNewProvider(data): Promise<any> {
    return await this.http.post<any>(this.baseUrl, data).toPromise();
  }

 
}
