import { Injectable, NgZone } from '@angular/core';

// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/fromEvent';
// import 'rxjs/add/operator/map';
import { Observable, fromEvent, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { UserManager, SigninResponse } from 'oidc-client';

function _window(): any {
    // return the global native browser window object
    return window;
}

@Injectable()
export class CordovaService {
    private resume: BehaviorSubject<boolean>;
    constructor(private zone: NgZone, private router: Router) {
        this.resume = new BehaviorSubject<boolean>(null);
        fromEvent(document, 'resume').subscribe(event => {
            this.zone.run(() => {
                this.onResume();
            });
        });
    }

    get cordova(): any {
        return _window().cordova;
    }
    get onCordova(): Boolean {
        return !!_window().cordova;
    }
    public onResume(): void {
        this.resume.next(true);
    }


    public getMacAddress(): string {
        if (this.onCordova) {
            return _window().device.uuid;
        }
        return null;
    }

    public openLinkInBrowser(url: string, manager: UserManager) {

        // let signinResponse: SigninResponse;
        if (this.onCordova) {
            _window().SafariViewCnontroller.isAvailable(function (available) {
                if (available) {
                    console.log('safari');
                    _window().SafariViewController.show({
                        url: url,
                        barColor: '#f7f7f9',
                        tintColor: '#1ca8dd',
                        controlTintColor: '#1ca8dd',
                    });
                } else {
                    console.log('in app browser');
                    _window().cordova.InAppBrowser.open(url, '_blank');
                }
            });

            // console.log('in app browser');
            // this.loginInAppBrowser(url).then(redirect_uri => {
            //     console.log('redirect_uri:' + redirect_uri);

            //     manager.processSigninResponse(redirect_uri).then((response: SigninResponse) => {
            //         console.log(response);
            //         // resolve(response);
            //         signinResponse = response;
            //     });

            //     // manager.signinRedirectCallback().then(user => {
            //     //     console.log('user: ' + user);
            //     // });
            // }, (error) => {
            //     console.log(error);
            // });
        } else {
            console.log('in browser');
            window.open('https://www.codexworld.com', '_blank');
        }
        // return new Promise(function (resolve, reject) {
        //     if (signinResponse !== null) {
        //         resolve(signinResponse);
        //     } else {
        //         reject(null);
        //     }
        // });
    }

    private loginInAppBrowser(url): Promise<any> {
        return new Promise(function (resolve, reject) {
            const browserRef = _window().cordova.InAppBrowser.open(url, '_blank',
                'location=no,clearsessioncache=yes,clearcache=yes');
            browserRef.removeEventListener('exit', (event) => {
                console.log('on exit');
                browserRef.close();
            });
            browserRef.addEventListener('loadstop', (event) => {
                console.log('on loadstop');

                console.log('index: ' + (event.url).indexOf('http://localhost'));

                if ((event.url).indexOf('http://localhost') !== -1) {

                    browserRef.close();
                    const lastIndex = event.url.lastIndexOf('?');
                    console.log('event url: ' + event.url);

                    const code: string | undefined = event.url.substring(lastIndex);

                    console.log('code: ' + code);

                    // if (lastIndex === -1) {
                    //     reject('Hash is not valid');
                    // }
                    // const responseHash = ((event.url).substring(++lastIndex));
                    resolve(event.url);
                } else {
                    // reject('Check your identityserver redirect uri');
                }
            });
        });
    }
}
