import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

//Created
import { PaymentService } from "../../services/payment.service";
import { AppTranslationService } from '../../services/app-translation.service';
import { IPayPaymentService } from 'src/app/services/ipay-payment.service';

@Component({
  selector: "app-payment-confirmation",
  templateUrl: "./payment-confirmation.component.html",
  styleUrls: ["./payment-confirmation.component.scss"]
})
export class PaymentConfirmationComponent implements OnInit {
  transactionId: string;
  resourcePath: string;
  confirmationRequestDone: boolean = false;
  message: string = "";
  private confirmationResult: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: IPayPaymentService,
    private translationService: AppTranslationService
  ) {}

  gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.transactionId = params["transactionid"];

      if (this.transactionId == undefined || this.transactionId == null) {
        this.confirmationRequestDone = true;

        //TODO u Tranlsator, vec ima kljuc
        this.message = this.gT('paymentConfirmationPage.unknownCode');
      } else {
        this.paymentService
          .getTransactionInfo(this.transactionId)
          .subscribe(response => {
            console.log(response);

            this.message = this.gT('paymentConfirmationPage.successfullyExecuted');
            this.confirmationResult = response["data"];
            this.confirmationRequestDone = true;
          });
      }
    });
  }

  onBackToDocumentClick() {
    this.router.navigate(["/"]);
  }
}
