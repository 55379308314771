import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}

let clientid = environment.clientId;
const providers = [
  { provide: "CLIENT_ROOT", useFactory: getBaseUrl, deps: [] },
  { provide: "AUTH_URL", useValue: environment.AUTH_URL },
  { provide: "API_URL", useValue: environment.API_URL },
  { provide: "ADMIN_PANEL_URL", useValue: environment.ADMIN_PANEL_URL },
  { provide: "IS_PAYMENT_ENABLED", useValue: environment.IS_PAYMENT_ENABLED },
];

// setup za web
console.log("AUTH_URL: " + environment.AUTH_URL);
console.log("API_URL: " + environment.API_URL);
platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
// // #########################

// setup za cordovu
// const onDeviceReady = () => {
//   console.log('on device ready');
//   console.log('AUTH_URL: ' + environment.AUTH_URL);
//   console.log('API_URL: ' + environment.API_URL);

//   platformBrowserDynamic(providers).bootstrapModule(AppModule).catch(err => console.log(err));
//   };
// document.addEventListener('deviceready', onDeviceReady, false);
// ###########
