import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  UrlSerializer,
  DefaultUrlSerializer,
  UrlTree
} from "@angular/router";

//Created
import { Utilities } from "./services/utilities";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { NotFoundPage } from "./pages/not-found/not-found-page.component";
import { AuthGuard } from "./services/auth-guard.service";
import { ProvidersComponent } from "./pages/providers/providers.component";
import { UnauthorizedComponent } from "./pages/unauthorized/unauthorized.component";
import { NewProviderComponent } from "./pages/new-provider/new-provider.component";
import { MainWrapperComponent } from "./pages/main-wrapper/main-wrapper.component";
import { LoginComponent } from './pages/login/login.component';
import { SigninRedirectCallbackComponent } from './pages/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './pages/signout-redirect-callback-component/signout-redirect-callback-component.component';
import { SubscriptionDetailsComponent } from './pages/subscription-details/subscription-details.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { AddressComponent } from './components/address/address.component';
import { ProviderComponent } from './pages/provider/provider.component';
import { DocumentDetailsComponent } from './pages/document-details/document-details.component';
import { MarkAsPaidComponent } from './pages/mark-as-paid/mark-as-paid.component';
import { SharedSubscriptionComponent } from './components/shared-subscription/shared-subscription.component';
import { CardPaymentComponent } from './pages/card-payment/card-payment.component';
import { PaymentConfirmationComponent } from './pages/payment-confirmation/payment-confirmation.component';
import { SubscribersComponent } from './pages/subscribers/subscribers.component';
import { ShareDocumentComponent } from './pages/share-document/share-document.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { IPayPaymentComponent } from './components/ipay-payment/ipay-payment.component';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const possibleSeparators = /[?;#]/;
    const indexOfSeparator = url.search(possibleSeparators);
    let processedUrl: string;

    if (indexOfSeparator > -1) {
      const separator = url.charAt(indexOfSeparator);
      const urlParts = Utilities.splitInTwo(url, separator);
      urlParts.firstPart = urlParts.firstPart.toLowerCase();

      processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
    } else {
      processedUrl = url.toLowerCase();
    }

    return super.parse(processedUrl);
  }
}

const routes: Routes = [
  {
    path: "",
    component: MainWrapperComponent,
    data: { title: "Pocetna" },
    children: [
      { path: "", component: DashboardComponent, canActivate: [AuthGuard] },
      {
        path: "document/checkout", // multiple documents payment path
        component: IPayPaymentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "document/:documentid/checkout",
        component: IPayPaymentComponent, //CardPaymentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "document/:documentid",
        component: DocumentDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "document/:documentid/subscribers",
        component: SubscribersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "document/:documentid/share",
        component: ShareDocumentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "document/:documentid/preview",
        component: DocumentPreviewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "provider",
        component: ProvidersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "subscription",
        component: SubscriptionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "document/:documentid/mark-as-paid",
        component: MarkAsPaidComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "provider/:friendlyProviderName",
        component: ProviderComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "subscription/:id",
        component: SubscriptionDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "new-provider",
        component: NewProviderComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "profile",
        component: AddressComponent,
        canActivate: [AuthGuard]
      },
       {
        path: "document/byprovider/:providerid",
        component: DashboardComponent,
        canActivate: [AuthGuard]
       },
       {
        path: "shared-subscription",
        component: SharedSubscriptionComponent,
        canActivate: [AuthGuard]
       },
       {
        path: "contact",
        component: ContactComponent,
        canActivate: [AuthGuard]
       }
    ]
  },
  { path: "signin-callback", component: SigninRedirectCallbackComponent },
  { path: "signout-callback", component: SignoutRedirectCallbackComponent },
  { path: "payment/:transactionid/confirm", component: PaymentConfirmationComponent },
  { path: "login", component: LoginComponent },
  { path: "unauthorized", component: UnauthorizedComponent },
  { path: "**", component: NotFoundPage },

];

// const routes: Routes = [
//   // { path: "", component: ChechAuthenticationComponent, data: { title: "Pocetna" }},
//   {
//     path: '',
//     component: AuthorizationComponent,
//     data: { title: "Pocetna" }
//   },
//   { path: 'dashboard', component: HomeComponent, canActivate:[AuthGuard]},
//   { path: 'provider', component: ProvidersComponent, canActivate:[AuthGuard]},
//   { path: 'provider/:{friendlyProviderName}', component: ConnectionComponent, canActivate:[AuthGuard]},
//   { path: 'new-provider', component: NewProviderComponent, canActivate:[AuthGuard]},

//   { path: 'login', component: AuthorizationComponent },
//   { path: 'unauthorized', component: UnauthorizedComponent },
//   { path: '**', component: NotFoundPage }
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [{ provide: UrlSerializer, useClass: LowerCaseUrlSerializer }]
})
export class AppRoutingModule {}
