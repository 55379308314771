import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  baseUrl = `${this.apiUrl}`;


  constructor(
    @Inject("API_URL") private apiUrl: string,

    private http: HttpClient) { }


  public async AddNewSubscription(id):  Promise<any> {
    const url = `${this.baseUrl}/subscription/${id}`;
    return await this.http.post<any>(url, id).toPromise();
  }

  public async UpdateSubscription(data): Promise<any>{
    const url = `${this.baseUrl}/subscription`;
    return await this.http.post<any>(url, data).toPromise();
  }

  public async  changeStatus(data): Promise<any>{
    const url = `${this.baseUrl}/subscription/changestatus`;
    return await this.http.post<any>(url, data).toPromise();
  }

  public async deleteSubscription(id): Promise<any>{
    const url = `${this.baseUrl}/subscription/${id}`;  
    return await this.http.delete<any>(url, id).toPromise();
  }
 

}
