import { Injectable } from "@angular/core";
import { DocumentHeader } from "../models/document-header";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DocumentHandlerService {
  public selectedDocuments: DocumentHeader[] = [];

  public onDocumentSelected: BehaviorSubject<DocumentHeader[]>;

  constructor() {
    this.onDocumentSelected = new BehaviorSubject(this.selectedDocuments);
  }

  addSelectedDocument(document: DocumentHeader) {
    let docs = this.selectedDocuments.filter((d) => d.id == document.id);
    if (docs.length == 0) {
      this.selectedDocuments.push(document);

      this.onDocumentSelected.next(this.selectedDocuments);
    }
  }

  removeSelectedDocument(document: DocumentHeader) {
    let index = this.selectedDocuments.indexOf(document);

    if (index >= 0) {
      this.selectedDocuments.splice(index, 1);

      this.onDocumentSelected.next(this.selectedDocuments);
    }
  }

  clearAll() {
    this.selectedDocuments.forEach((element) => {
        element["selected"] = false;
    });
    this.selectedDocuments = [];

    this.onDocumentSelected.next(this.selectedDocuments);
  }
}
