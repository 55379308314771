import { Injectable, OnDestroy, Inject } from "@angular/core";
import {
  AuthorizationServiceConfiguration,
  FetchRequestor,
  AuthorizationRequestHandler,
  AuthorizationRequest,
  AuthorizationResponse,
  TokenResponse,
  RedirectRequestHandler,
  AuthorizationNotifier,
  TokenRequestHandler,
  BaseTokenRequestHandler,
  TokenRequest,
  StringMap,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  LocalStorageBackend,
  DefaultCrypto,
  BasicQueryStringUtils,
  StorageBackend,
  TokenResponseJson,
} from "@openid/appauth";

import { ActivatedRoute, Router } from "@angular/router";
//import { NoHashQueryStringUtils } from "./noHashQueryStringUtils";
import { TokenManager } from "./app-auth/tokenManager";
import { Observable, timer, Subject, of, Subscription } from "rxjs";
import { takeUntil, switchMap, catchError } from "rxjs/operators";
import { CordovaService } from "./cordova.service";
import { CordovaAppBrowserProvider } from "./app-auth/CordovaAppBrowser";
import {
  CordovaAuthorizationRequestHandler,
  AUTHORIZATION_RESPONSE_KEY,
} from "./app-auth/CordovaAuthorizationRequestHandler";
import { EndSessionRequest } from "./app-auth/endSessionRequest";
import { EndSessionHandler } from "./app-auth/EndSessionRequestHandler";
import { environment } from "src/environments/environment";
import { NoHashQueryStringUtils } from "../noHashQueryStringUtils";
import { JwtHelperService } from "./jwt-helper.service";
import { AccessToken } from "../models/access-token.model";
import { UserJson, User } from "../models/user.model";
import { FcmService } from './fcm.service';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  // For Plativoo
  //private OPServer = environment.OPServer;
  private clientId = environment.clientId;
  private redirectUri = environment.redirectURL;
  private scope = environment.scope;
  private endSessionRedirectUri = environment.endSessionRedirectUri;

  private authFinishedCallback: Function;
  private authLogOutCallback: Function;

  private configuration: AuthorizationServiceConfiguration = null;
  private authorizationHandler: AuthorizationRequestHandler;
  private endSessionHandler: EndSessionHandler;
  private request: AuthorizationRequest | undefined;
  private response: AuthorizationResponse | undefined;
  private code: string | undefined;
  //public tokenResponse: TokenResponse | undefined;
  private tokenHandler: TokenRequestHandler;
  private notifier: AuthorizationNotifier;
  private error: any = null;
  private storageBackend: StorageBackend;
  private currentUser;

  private timer = null;

  refresher: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tokenManager: TokenManager,
    private cordovaService: CordovaService,
    private cordovaBrowserView: CordovaAppBrowserProvider,
    private jwtHelperService: JwtHelperService,
    private fcmService: FcmService,
    @Inject("AUTH_URL") private OPServer: string
  ) {
    //console.log("Is on cordova:" + this.cordovaService.onCordova);
    this.storageBackend = new LocalStorageBackend();

    if (this.cordovaService.onCordova) {
      //else if cordova
      //IonicAuthorizationRequestHandler extend RedirectRequestHandler
      //this.authorizationHandler.performAuthorizationRequest overwriten
      this.authorizationHandler = new CordovaAuthorizationRequestHandler(
        this.cordovaBrowserView
      );
    } else {
      this.authorizationHandler = new RedirectRequestHandler(
        new LocalStorageBackend(),
        new NoHashQueryStringUtils(),
        window.location,
        new DefaultCrypto()
      );
    }

    this.endSessionHandler = new EndSessionHandler(
      this.cordovaBrowserView,
      this.cordovaService
    );

    this.notifier = new AuthorizationNotifier();
    this.tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

    this.authorizationHandler.setAuthorizationNotifier(this.notifier);

    this.notifier.setAuthorizationListener((request, response, error) => {
      //console.log("Authorization request complete ", request, response, error);
      if (response) {
        this.request = request;
        this.response = response;
        this.code = response.code;
        //console.log(`Authorization Code ${response.code}`);

        this.makeTokenRequest();
      }
    });
  }
  ngOnDestroy(): void {
    //this.refresher.unsubscribe();
  }

  makeTokenRequest() {
    this.fetchServiceConfiguration().then((config) => {
      let request: TokenRequest | null = null;
      if (this.code) {
        let extras: StringMap | undefined = undefined;
        if (this.request && this.request.internal) {
          extras = {};
          extras["code_verifier"] = this.request.internal["code_verifier"];
        }
        // use the code to make the token request.
        request = new TokenRequest({
          client_id: this.clientId,
          redirect_uri: this.redirectUri,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: this.code,
          refresh_token: undefined,
          extras: extras,
        });
      } else if (this.tokenManager.tokenResponse) {
        // use the token response to make a request for an access token
        request = new TokenRequest({
          client_id: this.clientId,
          redirect_uri: this.redirectUri,
          grant_type: GRANT_TYPE_REFRESH_TOKEN,
          code: undefined,
          refresh_token: this.tokenManager.tokenResponse.refreshToken,
          extras: undefined,
        });
      }

      if (request) {
        this.tokenHandler
          .performTokenRequest(config, request)
          .then((response) => {
            let isFirstRequest = false;
            if (this.tokenManager.tokenResponse) {
              // copy over new fields
              this.tokenManager.tokenResponse.accessToken = response.accessToken;
              this.tokenManager.tokenResponse.issuedAt = response.issuedAt;
              this.tokenManager.tokenResponse.expiresIn = response.expiresIn;
              this.tokenManager.tokenResponse.tokenType = response.tokenType;
              this.tokenManager.tokenResponse.scope = response.scope;
              this.tokenManager.tokenResponse.refreshToken = response.refreshToken;
            } else {
              isFirstRequest = true;
              this.tokenManager.tokenResponse = response;
            }

            // unset code, so we can do refresh token exchanges subsequently
            this.code = undefined;
            if (isFirstRequest) {
              //console.log(`Obtained a refresh token ${response.refreshToken}`);
            } else {
              //console.log(`Obtained an access token ${response.accessToken}.`);
            }

            this.tokenManager.setToken(this.tokenManager.tokenResponse);
            //this.refresher.unsubscribe();
            //timer.un
            //this.refresher = timer(this.refreshTime(this.tokenResponse.expiresIn)).subscribe(() => this.refreshToken());
            this.timer = setInterval(() => {
              this.refreshToken();
            }, this.refreshTime(this.tokenManager.tokenResponse.expiresIn));

            //console.log('ovo treba da se vidi u consoli!!!!');

            this.authFinishedCallback();
          })
          .catch((error) => {
            console.log(`Something bad happened ${error}`);
          });
      }
    });
  }

  signin() {
    this.fetchServiceConfiguration().then((config) => {
      const authRequest = new AuthorizationRequest({
        client_id: environment.clientId,
        redirect_uri: environment.redirectURL,
        scope: environment.scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: undefined,
        // extras: environment.extra
      });
      //const url = this.authorizationHandler.

      //console.log(JSON.stringify(authRequest));
      this.authorizationHandler.performAuthorizationRequest(
        config,
        authRequest
      );
    });
  }

  refreshToken(): Promise<TokenResponse> {
    const promise = new Promise<TokenResponse>((resolve, reject) => {
      this.fetchServiceConfiguration().then((config) => {
        try {
          let request = new TokenRequest({
            client_id: this.clientId,
            redirect_uri: this.redirectUri,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            code: undefined,
            refresh_token: this.tokenManager.tokenResponseJson.refresh_token, //this.tokenManager.getToken()["refresh_token"],
            //refresh_token: this.tokenResponse.refreshToken,
            extras: undefined,
          });

          this.tokenHandler
            .performTokenRequest(config, request)
            .then((response) => {
              console.log(`Refresh Token is ${response.refreshToken}`);
              this.tokenManager.tokenResponse = response;
              this.tokenManager.setToken(this.tokenManager.tokenResponse);

              this.refresher = null;
              //this.refresher = timer(this.refreshTime(this.tokenResponse.expiresIn)).subscribe(() => this.refreshToken());
              this.timer = setInterval(() => {
                this.refreshToken();
              }, this.refreshTime(this.tokenManager.tokenResponse.expiresIn));
              resolve(this.tokenManager.tokenResponse);
            })
            .catch((error) => {
              console.log(`Something bad happened ${error}`);
              reject({ status: 400 });
            });
        } catch (error) {
          reject({ status: 400 });
        }
      });
    });

    return promise;
  }

  public async signout() {
    this.fetchServiceConfiguration().then((config) => {
      let id_token = this.tokenManager.tokenResponse.idToken;
      let request = new EndSessionRequest(id_token, this.endSessionRedirectUri);

      this.endSessionHandler.performEndSessionRequest(
        this.configuration,
        request
      );
    });
  }

  handleCode() {
    this.code = this.route.snapshot.queryParams.code;

    if (!this.code) {
      this.error = "Unable to get authorization code";
      return;
    }
    this.authorizationHandler.completeAuthorizationRequestIfPossible();
  }

  fetchServiceConfiguration(): Promise<AuthorizationServiceConfiguration> {
    let self = this;
    const promise = new Promise<AuthorizationServiceConfiguration>(function (
      resolve,
      reject
    ) {
      if (self.configuration != null) {
        resolve(self.configuration);
      } else {
        AuthorizationServiceConfiguration.fetchFromIssuer(
          self.OPServer,
          new FetchRequestor()
        ).then((response) => {
          //console.log("Fetched service configuration", response);
          self.configuration = response;

          resolve(self.configuration);
        });
      }
    });

    return promise;
  }

  public isAuthenticated() {
    var res = this.tokenManager.tokenResponse ? this.tokenManager.tokenResponse.isValid(10) : false;

    //console.log("Authenticate: " + res);

    return res;
  }

  public async startupAsync(
    signInCallback: Function,
    signOutCallback: Function
  ) {
    this.authFinishedCallback = signInCallback;
    this.authLogOutCallback = signOutCallback;

    await this.tryLoadTokenResponseAsync();
  }

  public getCurrentUser() {
    const decodedAccessToken = <AccessToken>(
      this.jwtHelperService.decodeToken(this.tokenManager.tokenResponse.accessToken)
    );

    let user: UserJson = {
      id: decodedAccessToken.sub,
      email: decodedAccessToken.email,
      fullName: decodedAccessToken.full_name,
      plativooId: decodedAccessToken.plativooId,
      municipalityId: decodedAccessToken.municipalityId,
      role: decodedAccessToken.role,
      aduptod: decodedAccessToken.aduptod,
      providers: JSON.parse(decodedAccessToken.providers),
      fcmtoken: decodedAccessToken.fcmtoken,
    };

    let _this=this;
    if (this.cordovaService.onCordova) {

      let token = this.fcmService.getToken(user.fcmtoken, function(fcmtoken){
        //console.log(fcmtoken);

        _this.fcmService.subscribeAll(user.providers);
        _this.onFcmNotification();
      });
    }


    return new User(user);
  }

  private async tryLoadTokenResponseAsync(): Promise<TokenResponse> {
    let token = this.tokenManager.getToken();

    if (token) {
      this.tokenManager.tokenResponse = token;
      this.tokenManager.setToken(token);
      this.timer = setInterval(() => {
        this.refreshToken();
      }, this.refreshTime(this.tokenManager.tokenResponse.expiresIn));
    }

    return this.tokenManager.tokenResponse;
  }

  public async AuthorizationCallback(url: string) {
    //console.log("RedirectUrl: " + this.redirectUri);

    if (url.indexOf(this.redirectUri) === 0) {
      this.cordovaBrowserView.CloseWindow();
      await this.storageBackend.setItem(AUTHORIZATION_RESPONSE_KEY, url);
      this.authorizationHandler.completeAuthorizationRequestIfPossible();
    }
    // else if (url.indexOf(EndSessionRedirectUri) === 0) {
    //   this.cordovaBrowserView.CloseWindow();
    //   await this.storageBackend.clear();
    //   await this.resetAuthCompletedPromise();
    //   delete this.tokenResponse;

    //   this.authLogOutCallback();
    // }
  }

  private refreshTime(expiresIn: number): number {
    clearInterval(this.timer);
    //console.log(expiresIn);

    const refreshIn: number = expiresIn * 1000 - 60 * 1000;

    let t = new Date();
    t.setSeconds(t.getSeconds() + refreshIn / 1000);
    //console.log(t.toTimeString());

    return refreshIn;
  }

  private onFcmNotification() {
		_window().FCMPlugin.onNotification(function(data) {
			if (data.wasTapped) {
				//Notification was received on device tray and tapped by the user.
				console.log(JSON.stringify(data));
				//this.navCtrl.setRoot('HomePage', { profileId: data.profileId });
			} else {
        //Notification was received in foreground. Maybe the user needs to be notified.

        //treba neki toastr da se prikaze i da javi korisniku info

				console.log(JSON.stringify(data));
				//this.navCtrl.push('HomePage', { profileId: data.profileId });
			}
		});
	}
}
