import { Component, OnInit, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

//Created
import { PaymentService } from "../../services/payment.service";
import { IViewBag } from "../../repo/interface/iview-bag";
import { ViewBagOrigin } from "../../core/enums";
import { CordovaService } from '../../services/cordova.service';
import { DocumentHandlerService } from 'src/app/services/document-handler.service';

@Component({
  selector: "app-card-payment",
  templateUrl: "./card-payment.component.html",
  styleUrls: ["./card-payment.component.scss"]
})
export class CardPaymentComponent implements OnInit {

  private document: any = null;
  public documentId: string;
  public paymentInfo: {};
  private src: any;
  checkoutResult: any = null;
  private scriptPath: any = null;
  private formAction: string = null;
  private confirmResult: any = null;
  private viewBag: IViewBag;
  public isMobile: boolean;

  public payClicked = false as boolean;

  constructor(
    private paymentService: PaymentService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private cordovaService: CordovaService,
    private deviceService: DeviceDetectorService,
    private documentHandler: DocumentHandlerService,
    @Inject("CLIENT_ROOT") private clientRoot: string,
    @Inject("AUTH_URL") private authRoot: string,
    @Inject("API_URL") private apiRoot: string
  ) {}

  async ngOnInit() {

    this.documentId = this.route.snapshot.paramMap.get("documentid");
    console.log(history.state);
    this.viewBag = history.state as IViewBag;

    if (this.viewBag.origin == ViewBagOrigin.DocumentDetails) {
      this.document = this.viewBag.data.document;
    }

    if (this.document === null) {
      this.location.back();
    }
    else{
      //calculate transation
      let response = await this.paymentService.calculateTransaction(this.documentId, this.document['totalAmount']);
      this.paymentInfo = response["data"];
    }
    this.isMobile = this.deviceService.isMobile();
  }

  async update(value) {

      let response = await this.paymentService.calculateTransaction(this.documentId, value);
      this.paymentInfo = response["data"];
  }

  onButoonPayClick() {

    this.payClicked = true;
    let documentId = this.documentId;
    let paymentInfo = this.paymentInfo;

    this.paymentService
      .getPaymentForm(documentId, paymentInfo)
      .subscribe((response: any) => {
        let payInfo = response.data;

        console.log(payInfo);

        this.scriptPath = `${payInfo.path}/paymentWidgets.js?checkoutId=${payInfo.id}`;
        //this.formAction = `${this.apiUrl}/payment/confirm/${this.documentId}/${payInfo.id}`;

        console.log(this.clientRoot);
        let url="";

        if(this.cordovaService.onCordova){
          //TODO placanje ne radi na mobilnoj aplikaciji
          url=`${this.apiRoot}/document/${this.documentId}/confirm`;
          //url='https://plativooids.dewebeloper.com/fw.html';
        }
        else{
          url=`${this.clientRoot}document/${this.documentId}/confirm`;
        }

        this.formAction = url;

        this.loadScript(this.scriptPath);
        this.checkoutResult = response.data;
      });
  }

  onButonGoBackClick(){
    this.location.back();
  }

  private loadScript(url: string) {

    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = url;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }

}
