import {
  Component,
  OnInit,
  Renderer,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { DomSanitizer } from "@angular/platform-browser";
import { Location } from "@angular/common";

//Created
import { DocumentService } from "../../services/document.service";
import { TagService } from '../../services/tag.service';

//https://www.npmjs.com/package/ngx-device-detector
@Component({
  selector: "document-details",
  templateUrl: "./document-details.component.html",
  styleUrls: ["./document-details.component.scss","./custom-theme.scss" ],
})
export class DocumentDetailsComponent implements OnInit {
  document: any = null;
  invoiceId: string;
  documentPreviewHtml;
  loading = false as boolean;
  isMobile;
  transactionDetails;
  items;

  //@ViewChild("documentPreviewHtml", {static: false, read: ViewContainerRef }) documentPreviewHtml;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private deviceService: DeviceDetectorService,
              private documentService: DocumentService,
              private sanitizer: DomSanitizer,
              private renderer: Renderer,
              private location: Location,
              private tagService: TagService) {
    this.invoiceId = this.route.snapshot.paramMap.get("documentid");
  }

  async ngOnInit() {
    this.loading = true;
    this.isMobile = this.deviceService.isMobile();
    //console.log("Mobile: " + this.isMobile);
    await this.getDocument();
  }

  async getDocument() {
    await this.documentService
      .getDocumentById(this.invoiceId)
      .then(async (document) => {
        this.document = document;
        this.items = document.tags;
        //console.log("doc:" + document);
        this.loading = false;

        // if (this.deviceService.isMobile() == false) {
        //   //ako nismo na mobilno pun prikaz računa
        //   this.documentService
        //     .getDocumentTemplatePreview(this.invoiceId)
        //     .subscribe((html) => {
        //       this.documentPreviewHtml = this.sanitizer.bypassSecurityTrustHtml(html);
        //       this.loading = false;
        //     });
        // } else {
        //   //ako smo na mobilnom, prikaz generičkog računa
        //   console.log("doc:" + document);
        //   this.loading = false;
        // }
      });
  }

  qrCode(imageData: string) {
    return this.sanitizer.bypassSecurityTrustUrl(
      "data:Image/*;base64," + imageData
    );
  }

  async onTagAdded (event) {
    //console.log('Tag added' + event.value);

    let result = await this.tagService.addTag(this.invoiceId,event.value);

  }

  async onTagRemoved (event) {
    let result = await this.tagService.removeTag(this.invoiceId,event);
  }

  goToShare () {
    this.router.navigateByUrl("/document/" + this.document.id + '/share');
  }


  onTransactionGet($event){
    console.log($event);
    this.transactionDetails = $event;
  }

  copyToClipBoard(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = JSON.stringify(val);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
