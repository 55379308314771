import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//Created
import { ToastService } from '../../services/toast.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ToastType } from '../../core/enums';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,             
              private toastService: ToastService,
              private translationService: AppTranslationService,
              private contactService: ContactService,
              private router : Router) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ["", Validators.required],
      title: ["", Validators.required],
      message: [""],
      section: ["", Validators.required],
    });
  }

  gT = (key: string | Array<string>, interpolateParams?: Object) =>
    this.translationService.getTranslation(key, interpolateParams);
  get f() {
    return this.contactForm.controls;
  }

  async onSubmit(contactForm) {
    this.submitted = true;
    if (this.contactForm.invalid || this.contactForm == undefined) {
      let message = this.gT("contactPage.error");
      this.toastService.newToast(
        this.gT("subscriptionDetailsPage.error"),
        message,
        ToastType.Error
      );
      return;
    }

    try {
      let result = await this.contactService.SendMessage(contactForm.value);
      this.toastService.newToast(this.gT('subscriptionDetailsPage.success'), this.gT('contactPage.successMessage'), ToastType.Success);
      this.router.navigateByUrl('/');

    } catch (error) {
      this.toastService.newToast(
        this.gT("subscriptionDetailsPage.error"),
        error.error.message,
        ToastType.Error
      );
    }
  }

  cancel() {
    this.router.navigateByUrl('/');
  }
  
}
