import { FormGroup, ValidationErrors,ValidatorFn, Validators, } from '@angular/forms';
import { stringify } from 'querystring';


export function mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
}

export function usernameValidator(control) {
    if (control.value) {
      const matches = control.value.match(/^[A-Za-z0-9_]+$/);
      return matches ? '' : { 'invalidUsername' : true };
    } else {
      return '';
    }
}

export function PlativooIDValidator(control) {
  if (control.value) {
    const matches = control.value.match(/[A-Z]{2}\-[0-9]{5}\-[A-Z]{3}/g);
    return matches ? '' : { 'invalidPlativooID' : true };
  } else {
    return '';
  }
}


export const atLeastOne = (validator: ValidatorFn, controls:string[] = null) => (
    group: FormGroup,
  ): ValidationErrors | null => {
    if(!controls){
      controls = Object.keys(group.controls)
    }

    const hasAtLeastOne = group && group.controls && controls
      .some(k => !validator(group.controls[k]));

    return hasAtLeastOne ? null : {
      atLeastOne: true,
    };
  };



//Ako nekoristimo formu već model
  export function emailModelValidator(value) {
    if (value !== '' && value !== undefined && value !== null) {
      const matches = value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? true : false;
    } else {
      return null;
    }
}

//Ako nekoristimo formu već model
export function PlativooIDModelValidator(value) {
  if (value !== '' && value !== undefined && value !== null) {
    const matches = value.match(/[A-Z]{2}\-[0-9]{5}\-[A-Z]{3}/g);
    return matches ? true : false;
  } else {
    return false;
  }
}