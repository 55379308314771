import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bill-filter-modal',
  templateUrl: './bill-filter-modal.component.html',
  styleUrls: ['./bill-filter-modal.component.scss']
})
export class BillFilterModalComponent implements OnInit {

  documents;
  
  constructor( private modalService: NgbModal,
               public activeModal: NgbActiveModal ) { }
  
  ngOnInit() {
  }

  onDocumentSearch($event) {
    console.log($event);
    this.documents = $event;
  }

  async closeModal() {
        
      this.activeModal.close(this.documents);  
  }

}