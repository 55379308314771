import { EndSessionRequest } from './endSessionRequest';
import { AuthorizationServiceConfiguration, StringMap, BasicQueryStringUtils } from "@openid/appauth";
import { CordovaAppBrowserProvider } from './CordovaAppBrowser';
import { CordovaService } from '../cordova.service';


export class EndSessionHandler {

    constructor(
        private ionicBrowserView: CordovaAppBrowserProvider,
        private cordovaService: CordovaService,
        private utils = new BasicQueryStringUtils()
        ) {}

    public async performEndSessionRequest(configuration: AuthorizationServiceConfiguration, request : EndSessionRequest): Promise<any> {

      console.log('performEndSessionRequest');


        //Build the request
        let url = this.buildRequestUrl(configuration, request);


        if(this.cordovaService.onCordova){
        //Show in Browser Window
          await this.ionicBrowserView.ShowWindow(url);
        }
        else{
          window.open(url, '_self');
        }
    }

    private buildRequestUrl(configuration: AuthorizationServiceConfiguration,  request: EndSessionRequest) {
      // build the query string
      // coerce to any type for convenience
      let requestMap: StringMap = {
        'id_token_hint': request.idTokenHint,
        'post_logout_redirect_uri': request.postLogoutRedirectURI,
        'state': request.state,
      };

      let query = this.utils.stringify(requestMap);
      let baseUrl = configuration.endSessionEndpoint;
      let url = `${baseUrl}?${query}`;
      return url;
    }
}
