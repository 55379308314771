import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

//Created
import { ProvidersService } from "../../services/providers.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-providers",
  templateUrl: "./providers.component.html",
  styleUrls: ["./providers.component.scss"]
})
export class ProvidersComponent implements OnInit {
  providers: [] = null;

  constructor(
    private providersService: ProvidersService,
    private router: Router,
    private authService: AuthService
  ) {}

  //providers: any;
  municipalities: any;
  selectedMunicipality = null;
  searchString = "" as string;
  loading = false as boolean;

  userMunicipalityId = "" as string;
  currentUser;

  async ngOnInit() {  
    var user = this.authService.getCurrentUser();
    let municipalityId = '';
 
    if ( user.municipalityId !== undefined ){
        municipalityId = user.municipalityId;
    }
    
    this.loading = true;
    this.municipalities = await this.providersService.GetMunicipalityData(false);     
    this.providers = await this.providersService.GetProvidersData(municipalityId, '', false);  
    this.loading = false;
  }

  public async searchProviders() {
    let municipalityId = this.selectedMunicipality === null ? '' :  this.selectedMunicipality;
    this.providers = await this.providersService.GetProvidersData(municipalityId,this.searchString,true);
  }

  public selectProvider(provider) {
    // if (this.currentUser.aduptod === "False") {     
    //     this.router.navigateByUrl("/profile", { state: provider });
    // }else{
    //   this.router.navigateByUrl("/provider/" + provider.friendlyProviderName, {
    //     state: provider
    //   });
    // }    
    this.router.navigateByUrl("/provider/" + provider.urlFriendlyName, { state: provider });
  }

  addNewProvider() {
    this.router.navigateByUrl("/new-provider");
  }
}
