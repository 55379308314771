import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { TokenResponse, TokenResponseJson } from "@openid/appauth";
import { distinctUntilChanged, filter, take } from "rxjs/operators";

const TOKEN = "access_token";

@Injectable({
  providedIn: "root",
})
export class TokenManager {
  public tokenResponse: TokenResponse | undefined;
  private _tokenResponses: BehaviorSubject<TokenResponse>;
  public tokenResponseJson: TokenResponseJson;

  constructor() {
    let tokenResponse: TokenResponse | null = null;

    let token = this.getToken();

    if (token != null) {
      this._tokenResponses = new BehaviorSubject(token);
    } else {
      this._tokenResponses = new BehaviorSubject(null);
    }
  }

  public token(): Observable<TokenResponse> {
    return this._tokenResponses.asObservable().pipe(distinctUntilChanged());
  }

  public setToken(token: TokenResponse) {
    localStorage.setItem(TOKEN, JSON.stringify(token.toJson()));
    //console.log(token.accessToken);

    this.tokenResponseJson = token.toJson();
    this._tokenResponses.next(token);
  }

  public clearToken() {
    localStorage.removeItem(TOKEN);
    this.tokenResponse = null;
    this.tokenResponseJson = {} as TokenResponseJson;
    this._tokenResponses.next(null);
  }

  public getToken(): TokenResponse {
    this.tokenResponseJson = JSON.parse(localStorage.getItem(TOKEN));

    if (this.tokenResponseJson) {
      const response = new TokenResponse(this.tokenResponseJson);
      return response;
    }

    return null;
  }
}
