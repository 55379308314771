import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl = `${this.apiUrl}`;

  constructor(
    @Inject("API_URL") private apiUrl: string,

    private http: HttpClient
  ) { }

  public async SendMessage(data): Promise<any>{
    const url = `${this.baseUrl}/contact`;
    return await this.http.post<any>(url, data).toPromise();
  }
}
