import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Created
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-signin-redirect-callback',
  templateUrl: './signin-redirect-callback.component.html',
  styleUrls: ['./signin-redirect-callback.component.scss']
})
export class SigninRedirectCallbackComponent implements OnInit {

  constructor(private _authService: AuthService,
    private _router: Router) { }

  ngOnInit() {
    this._authService.handleCode();

    // this._authService.completeLogin().then(user => {
    //   //console.log(user);

    //   let providers = JSON.parse(user.profile.providers);
    //   if(providers.length === 0){
    //     this._router.navigateByUrl('provider')
    //   }else{
    //     this._router.navigate(['/'], { replaceUrl: true });
    //   }
    // })
  }

}
