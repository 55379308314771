import {
  Component,
  ViewChildren,
  QueryList,
  OnInit,
  Inject,
  NgZone,
} from "@angular/core";
import { AppTranslationService } from "./services/app-translation.service";
import { AuthService } from "./services/auth.service";
import { CordovaService } from "./services/cordova.service";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  title = "Plativoo";

  @ViewChildren("loginModal,loginControl")
  modalLoginControls: QueryList<any>;

  gT = (key: string | Array<string>, interpolateParams?: Object) =>
    this.translationService.getTranslation(key, interpolateParams);

  constructor(
    private translationService: AppTranslationService,
    private cordovaService: CordovaService,
    private authService: AuthService,
    private router: Router,
    private zone: NgZone,
    @Inject("CLIENT_ROOT") private clientRoot: string
  ) {
    sessionStorage.removeItem("HTTP_PARAMS");

    if (this.cordovaService.onCordova) {
      (<any>window).handleOpenURL = (url) => {
        //console.log("APP: " + url);

        if (url.indexOf(environment.endSessionRedirectUri) === 0) {
          //console.log("endsession");

          this.router.navigate(["signout-callback"]);
        } else if (url.indexOf("rs.plativoo://payment") === 0) {

          //console.log("Payment URL: " + url);
          let _url = url.replace("rs.plativoo://", "");
          //console.log(_url);
          this.router.navigate([_url]);
        } else {
          //console.log("auth callback url: " + url);

          this.authService.AuthorizationCallback(url);
        }
      };
    }

    this.authService.startupAsync(
      (signin) => {
        //Register the callback for when authService has tried handling signin
        //console.log("Treba da redirektuje na /");

        //this.router.navigate(["/"]);
        this.zone.run(() => {
          this.router.navigate(["/"]);
        });
      },
      (signout) => {
        this.zone.run(() => {
          this.router.navigate(["/"]);
        });
      }
    );
  }

  ngOnInit(): void {

    //console.log(window["config"]["AUTH_URL"]);

  }
}
