import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  baseUrl = `${this.apiUrl}`;

  constructor(@Inject("API_URL") private apiUrl: string,
                                 private http: HttpClient) { }

  public async  addTag(invoiceId: string, tag: string): Promise<any>{
    const url = `${this.baseUrl}/bill/${invoiceId}/tag/${tag}`;
    let data = {
      invoiceId: invoiceId,
      tag: tag
    }
    return await this.http.post<any>(url,data).toPromise();
  }

  public async removeTag(invoiceId: string, tag: string): Promise<any>{
    const url = `${this.baseUrl}/bill/${invoiceId}/tag/${tag}`;
    let data = {
      invoiceId: invoiceId,
      tag: tag
    }
    return await this.http.delete<any>(url).toPromise();
  }

}
