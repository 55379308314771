import { Injectable } from "@angular/core";

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable()
export class CordovaAppBrowserProvider {
  public async ShowWindow(url: string): Promise<any> {
    //console.log(_window().window.SafariViewController);
    _window().SafariViewController.isAvailable(async function (available) {
      if (available) {
        let optionSafari: any = {
          url: url,
          enterReaderModeIfAvailable: true,
        };

        await _window().SafariViewController.show(optionSafari).toPromise();

        // _window().SafariViewController.show(optionSafari, function(result) {
        //   if (result.event === 'opened') {
        //     console.log('opened');
        //   } else if (result.event === 'loaded') {
        //     console.log('loaded');
        //   } else if (result.event === 'closed') {
        //     console.log('closed');
        //   }
        // },
        // function(msg) {
        //   console.log("KO: " + msg);
        // });
      } else {
        //console.log("InAppBrowser Available");

        let options: any = {
          location: "no",
          zoom: "no",
          clearcache: "yes",
          clearsessioncache: "yes",
        };
        this.inAppLogin = _window().cordova.InAppBrowser.create(
          url,
          "_self",
          options
        );
        await this.inAppLogin.show();
      }
    });
  }

  //   if (await this.safariViewController.isAvailable()) {
  //     let optionSafari: SafariViewControllerOptions = {
  //       url: url,
  //       enterReaderModeIfAvailable: true,
  //     };
  //     await this.safariViewController.show(optionSafari).toPromise();
  //   } else {
  //     let options: InAppBrowserOptions = {
  //       location: "no",
  //       zoom: "no",
  //       clearcache: "yes",
  //       clearsessioncache: "yes",
  //     };

  //     this.inAppLogin = this.inAppBrowser.create(url, "_self", options);

  //     await this.inAppLogin.show();
  //   }

  public async CloseWindow() {
    if (await _window().SafariViewController.isAvailable()) {
      _window().SafariViewController.hide();
    } else {
      //this.inAppLogin.close();
    }
  }
}
