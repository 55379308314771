import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// https://www.npmjs.com/package/cordova-plugin-fcm-with-dependecy-updated/v/6.4.0

function _window(): any {
  // return the global native browser window object
  return window;
}
const FCM_SUBSCRIBERS = "FCM_SUBSCRIBERS";
@Injectable({
  providedIn: "root",
})
export class FcmService {
  private token: string = null;

  constructor(
    @Inject("API_URL") private apiRoot: string,
    private http: HttpClient
  ) {}

  subscribeAll(channels: any[]) {

    let _subscribers = localStorage.getItem(FCM_SUBSCRIBERS);
    //console.log(_subscribers);

    let subscribers:string[]=[];
    if(_subscribers != null || _subscribers != undefined){
      subscribers = _subscribers.split(',');
    }

    channels.forEach((element) => {
      let index = subscribers.indexOf(element);
      if ((index == -1)) {
        this.subscribe(element);

        subscribers.push(element);
      }
    });
    localStorage.removeItem(FCM_SUBSCRIBERS);
    localStorage.setItem(FCM_SUBSCRIBERS, subscribers.toString());
  }

  subscribe(channel: string) {
    let _chanel = localStorage.getItem(channel);

    if (_chanel == undefined || _chanel == null) {
      _window().FCMPlugin.subscribeToTopic(channel);
    } else {
      //console.log(val);
    }
  }

  getToken(globalfcmtoken: string, callback: Function) {
    let _this = this;
    if (this.token == null) {
      //console.log(_window().FCMPlugin);

      _window().FCMPlugin.getToken(function (fcmtok) {
        // console.log("FcmToken: " + fcmtok);
        _this.token = fcmtok;

        _this.updateUserFcmToken();

        if (typeof callback === "function") {
          callback(fcmtok);
        }
      });
    }
    return this.token;
  }

  updateUserFcmToken() {
    let currentToken = localStorage.getItem("FcmToken");

    //console.log(currentToken);
    if (currentToken == undefined) {
      //console.log("undf");
      this.fcmTokenRequest();
    } else {
      if (this.token != currentToken) {
        //console.log("not same");
        this.fcmTokenRequest();
      }
    }
  }

  private fcmTokenRequest() {
    //var url = this.apiConfig.path('/api/user');
    let url = `${this.apiRoot}/user`;

    var data = { fcmToken: this.token };
    return this.http.put(url, data).subscribe(
      (resposne) => {
        localStorage.setItem("FcmToken", this.token);

        //console.log(localStorage.getItem("FcmToken"));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onTokenRefresh() {
    _window()
      .FCMPlugin.onTokenRefresh()
      .subscribe((token) => {
        return token;
      });
  }
}
