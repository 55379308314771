//Libraries
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastaModule } from "ngx-toasta";
import { NgSelectModule } from "@ng-select/ng-select";
import { DeviceDetectorModule } from "ngx-device-detector";
import {
  NgbDatepickerModule,
  NgbModalModule,
  NgbPopoverModule,
  NgbCollapseModule,
  NgbDropdownModule
} from "@ng-bootstrap/ng-bootstrap";
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//Services
import {
  TranslateLanguageLoader,
  AppTranslationService,
} from "./services/app-translation.service";
import { LocalStoreManager } from "./services/local-store-manager.service";
import { AuthService } from "./services/auth.service";
import { CurrentUserService } from "./services/current-user.service";
import { ToastService } from "./services/toast.service";
import { AuthInterceptor } from "./services/auth-interceptor.service";
import { CordovaService } from "./services/cordova.service";
import { DocumentService } from "./services/document.service";

//Pages
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { NotFoundPage } from "./pages/not-found/not-found-page.component";
import { HasPermissionDirective } from "./directives/has-permission.directive";
import { ProvidersComponent } from "./pages/providers/providers.component";
import { UnauthorizedComponent } from "./pages/unauthorized/unauthorized.component";
import { ProviderComponent } from "./pages/provider/provider.component";
import { NewProviderComponent } from "./pages/new-provider/new-provider.component";
import { MainWrapperComponent } from "./pages/main-wrapper/main-wrapper.component";
import { SigninRedirectCallbackComponent } from "./pages/signin-redirect-callback/signin-redirect-callback.component";
import { SignoutRedirectCallbackComponent } from "./pages/signout-redirect-callback-component/signout-redirect-callback-component.component";
import { LoginComponent } from "./pages/login/login.component";
import { SubscriptionComponent } from "./pages/subscription/subscription.component";
import { DocumentDetailsComponent } from "./pages/document-details/document-details.component";
import { MarkAsPaidComponent } from "./pages/mark-as-paid/mark-as-paid.component";

//Components
import { FooterComponent } from "./components/footer/footer.component";
import { MainNavComponent } from "./components/main-nav/main-nav.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SubscriptionDetailsComponent } from "./pages/subscription-details/subscription-details.component";
import { DocumentTypeInvoiceComponent } from "./components/document-type-invoice/document-type-invoice.component";
import { AddressComponent } from "./components/address/address.component";
import { DocumentComponent } from "./components/document/document.component";
import { DocumentTypeMessageComponent } from "./components/document-type-message/document-type-message.component";
import { DocumentButtonsComponent } from "./components/document-buttons/document-buttons.component";
import { SharedSubscriptionComponent } from "./components/shared-subscription/shared-subscription.component";
import { WizardButtonComponent } from "./components/wizard-button/wizard-button.component";
import { DeleteSubModalComponent } from "./components/delete-sub-modal/delete-sub-modal.component";
import { PaymentService } from "./services/payment.service";
import { CardPaymentComponent } from "./pages/card-payment/card-payment.component";
import { PaymentConfirmationComponent } from "./pages/payment-confirmation/payment-confirmation.component";
import { DatepickerComponent } from "./components/datepicker/datepicker.component";
import { SubscribersComponent } from "./pages/subscribers/subscribers.component";
import { ShareDocumentComponent } from "./pages/share-document/share-document.component";
import { InfoSidebarComponent } from "./components/info-sidebar/info-sidebar.component";
import { InitialsPipe } from "./functions/get-initials-pipe";
import { GetMonthPipe } from "./functions/get-month-pipe";
import { registerLocaleData } from "@angular/common";
import localeSR from "@angular/common/locales/sr-Latn";
import { BillFiltersComponent } from './components/bill-filters/bill-filters.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BillFilterModalComponent } from './components/bill-filter-modal/bill-filter-modal.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';

import { CordovaAppBrowserProvider } from './services/app-auth/CordovaAppBrowser';
import { FcmService } from './services/fcm.service';
import { DocumentTypeProFormaComponent } from './components/dokument-type-pro-forma/document-type-pro-forma.component';
import { IPayPaymentComponent } from './components/ipay-payment/ipay-payment.component';


registerLocaleData(localeSR, "sr-Latn");

TagInputModule.withDefaults({
  tagInput: {
      placeholder: 'Unesite novi tag',
      // add here other default values for tag-input
  }
});


@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    NotFoundPage,
    HasPermissionDirective,
    ProvidersComponent,
    UnauthorizedComponent,
    ProviderComponent,
    NewProviderComponent,
    MainWrapperComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    LoginComponent,
    SubscriptionDetailsComponent,
    DocumentTypeInvoiceComponent,
    AddressComponent,
    SubscriptionComponent,
    DocumentComponent,
    DocumentTypeMessageComponent,
    DocumentDetailsComponent,
    DocumentButtonsComponent,
    DocumentButtonsComponent,
    MarkAsPaidComponent,
    SharedSubscriptionComponent,
    WizardButtonComponent,
    DeleteSubModalComponent,
    CardPaymentComponent,
    PaymentConfirmationComponent,
    DatepickerComponent,
    SubscribersComponent,
    ShareDocumentComponent,
    InfoSidebarComponent,
    InitialsPipe,
    GetMonthPipe,
    ContactComponent,
    BillFiltersComponent,
    BillFilterModalComponent,
    DocumentPreviewComponent,
    DocumentTypeProFormaComponent,
    IPayPaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader,
      },
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastaModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgSelectModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbPopoverModule,
    TagInputModule,
    NgbCollapseModule,
    NgbDropdownModule,
    BrowserAnimationsModule
  ],
  providers: [
    AppTranslationService,
    LocalStoreManager,
    ToastService,
    AuthService,
    CurrentUserService,
    FcmService,
    { provide: LOCALE_ID, useValue: "sr-Latn" },
    DocumentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CordovaService,
    PaymentService,
    CordovaAppBrowserProvider
  ],
  entryComponents: [DeleteSubModalComponent, BillFilterModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {

  }
}
