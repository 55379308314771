import { Component, OnInit, Input } from '@angular/core';

//Created
import { SubscriptionService } from '../../services/subscription.service';
import { ToastService } from '../../services/toast.service';
import { ToastType } from '../../core/enums';
import { AppTranslationService } from '../../services/app-translation.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-shared-subscription',
  templateUrl: './shared-subscription.component.html',
  styleUrls: ['./shared-subscription.component.scss']
})

export class SharedSubscriptionComponent implements OnInit {

  @Input() data;
  loading;

  constructor(
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private translationService: AppTranslationService) { }

  gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

  ngOnInit() {
    console.log(this.data);
  }

  async save(status, subdata) {
    this.loading = true;

    let data = {
      subscriptionid: subdata.id,
      userid: subdata.user.id,
      status: status
    }

    let result = null as any;
    try {
      result = await this.subscriptionService.changeStatus(data);
      this.toastService.newToast(this.gT('sharedSubscriptionComponent.success'), this.gT('sharedSubscriptionComponent.successMessage'), ToastType.Success);
      subdata.accountStatus.code = status;


    } catch (error) {
      this.toastService.newToast(this.gT('sharedSubscriptionComponent.error'), result.message, ToastType.Error);
    }

    this.loading = false;
  }


}
