import {
  AuthorizationRequestHandler,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  AuthorizationRequestResponse,
  StorageBackend,
  LocalStorageBackend,
  BasicQueryStringUtils,
  LocationLike,
  AuthorizationResponse,
  AuthorizationError,
  DefaultCrypto,
} from "@openid/appauth";
import { CordovaAppBrowserProvider } from "./CordovaAppBrowser";

/** key for authorization request. */
const authorizationRequestKey = (handle: string) => {
  return `${handle}_appauth_authorization_request`;
};

/** key for authorization service configuration */
const authorizationServiceConfigurationKey = (handle: string) => {
  return `${handle}_appauth_authorization_service_configuration`;
};

/** key in local storage which represents the current authorization request. */
const AUTHORIZATION_REQUEST_HANDLE_KEY =
  "appauth_current_authorization_request";
export const AUTHORIZATION_RESPONSE_KEY = "auth_response";

export class CordovaAuthorizationRequestHandler extends AuthorizationRequestHandler {
  generateRandom = new DefaultCrypto();
  constructor(
    // use the provided storage backend
    // or initialize local storage with the default storage backend which
    // uses window.localStorage
    private cordovaBrowserView: CordovaAppBrowserProvider,
    public storageBackend: StorageBackend = new LocalStorageBackend(),
    utils = new BasicQueryStringUtils(),
    public locationLike: LocationLike = window.location
  ) {
    super(utils, new DefaultCrypto());

    //console.log("Cordova AUthorization Request Handler CTOR");
  }

  public async performAuthorizationRequest(
    configuration: AuthorizationServiceConfiguration,
    request: AuthorizationRequest
  ): Promise<any> {
    // this.safariViewController.warmUp();

    let handle = this.generateRandom.generateRandom(128);

    // before you make request, persist all request related data in local storage.
    const newRequest: AuthorizationRequest = new AuthorizationRequest(
      {
        client_id: request.clientId,
        redirect_uri: request.redirectUri,
        scope: request.scope,
        response_type: request.responseType,
        state: request.state,
        extras: request.extras,
      },
      this.generateRandom,
      true
    );

    //console.log(newRequest);

    newRequest.toJson().then(async (rq) => {
      //console.log("start write");
      //const rq = JSON.stringify(request.toJson());
      let persisted = Promise.all([
        this.storageBackend.setItem(AUTHORIZATION_REQUEST_HANDLE_KEY, handle),
        this.storageBackend.setItem(
          authorizationRequestKey(handle),
          JSON.stringify(rq)
        ),
        this.storageBackend.setItem(
          authorizationServiceConfigurationKey(handle),
          JSON.stringify(configuration.toJson())
        ),
      ]);
      //console.log("end write");

      await persisted;

      let url = this.buildRequestUrl(configuration, newRequest);
      //console.log(url);

      this.cordovaBrowserView.ShowWindow(url).then((a) => {
        //console.log(a);
      });
    });
  }

  protected async completeAuthorizationRequest(): Promise<AuthorizationRequestResponse> {
    //console.log("Complete Authorization Request xxx");

    let handle = await this.storageBackend.getItem(
      AUTHORIZATION_REQUEST_HANDLE_KEY
    );
    //console.log(handle);

    if (!handle) {
      //Some error
      return null;
    }

    let authRequestKey = await this.storageBackend.getItem(
      authorizationRequestKey(handle)
    );
    //console.log('authRequestKey: ' + authRequestKey);

    let request = JSON.parse(authRequestKey);
    //console.log(request);

    //let request = await AuthorizationRequest.fromJson(json);
    //let request: AuthorizationRequest = JSON.parse(authRequestKey);
    //let request = JSON.parse(json);

    let response = await this.storageBackend.getItem(
      AUTHORIZATION_RESPONSE_KEY
    );
    let parts = response.split("?");

    //console.log(parts);

    if (parts.length !== 2) {
      throw new Error("Invalid auth repsonse string");
    }

    //Get the info from the calback URL
    let hash = parts[1];
    let queryParams = this.utils.parseQueryString(hash);

    let state: string | undefined = queryParams["state"];
    let code: string | undefined = queryParams["code"];
    let error: string | undefined = queryParams["error"];

    let authorizationResponse: AuthorizationResponse = null;
    let authorizationError: AuthorizationError = null;

    let shouldNotify = state === request.state;

    if (shouldNotify) {
      if (error) {
        let errorDescription = queryParams["error_description"];
        authorizationError = null; //new AuthorizationError({});
        // authorizationError = new AuthorizationError(
        //   error,
        //   errorDescription,
        //   undefined,
        //   state
        // );
      } else {
        authorizationResponse = new AuthorizationResponse({ code: code, state: state });
      }

      let tasks = new Array<Promise<any>>();
      {
        this.storageBackend.removeItem(AUTHORIZATION_REQUEST_HANDLE_KEY),
          this.storageBackend.removeItem(authorizationRequestKey(handle)),
          this.storageBackend.removeItem(
            authorizationServiceConfigurationKey(handle)
          );
      }

      await Promise.all(tasks);

      this.storageBackend.removeItem(AUTHORIZATION_RESPONSE_KEY);

      return <AuthorizationRequestResponse>{
        request: request,
        response: authorizationResponse,
        error: authorizationError,
      };
    }
  }
}
