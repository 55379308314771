export interface UserJson {
  id: string;
  email: string;
  fullName: string;
  plativooId: string;
  municipalityId: string;
  role: string | string[];
  aduptod: string;
  providers:[];
  fcmtoken: string;
}

export class User {
  public id: string;
  public email: string;
  public fullName: string;
  public plativooId: string;
  public municipalityId: string;
  public role: string | string[];
  public aduptod: string;
  public providers:[];
  public fcmtoken: string;

  constructor(user: UserJson) {
    this.id = user.id;
    this.email = user.email;
    this.fullName = user.fullName;
    this.plativooId = user.plativooId;
    this.municipalityId = user.municipalityId;
    this.role = user.role;
    this.aduptod = user.aduptod;
    this.providers = user.providers
    this.fcmtoken = user.fcmtoken
  }
}
