import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-sidebar',
  templateUrl: './info-sidebar.component.html',
  styleUrls: ['./info-sidebar.component.scss']
})
export class InfoSidebarComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  contactUs(){
    this.router.navigateByUrl('/contact');
  }

}
