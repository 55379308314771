import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wizard-button',
  templateUrl: './wizard-button.component.html',
  styleUrls: ['./wizard-button.component.scss']
})
export class WizardButtonComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  startWizard() {
    this.router.navigateByUrl('/provider');     
  }

}
