import { Component, OnInit } from "@angular/core";

//Created
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  onLoginButtonClick() {
    this.isLoading = true;
    this.authService.signin();
  }
}
