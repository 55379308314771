import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PaymentService {
  constructor(
    private http: HttpClient,
    @Inject("API_URL") private apiUrl: string
  ) {}

  baseUrl = `${this.apiUrl}/payment`;

  async calculateTransaction(invoiceId: string, totalAmount:number) :  Promise<any> {
    let url = `${this.baseUrl}/calculate/${invoiceId}`;
    return await this.http.post<any>(url, totalAmount).toPromise();
  }

  confirmPayment(documentId: string, checkoutId: string) {
    let url = `${this.baseUrl}/confirm/${documentId}/${checkoutId}`;
    return this.http.get(url);
  }

  getPaymentForm(documentId: string, paymentInfo: any) {
    //let url = `${this.baseUrl}/view/paymentform?checkoutid=${checkoutid}&invoiceid=${invoiceid}`;
    let url = `${this.baseUrl}/checkout/${documentId}`;
    return this.http.post(url, paymentInfo);
  }
}
