import { Component, OnInit, Input } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { DocumentHeader } from '../../models/document-header';

@Component({
  selector: '[document-type-proforma]',
  templateUrl: './document-type-pro-forma.component.html',
  styleUrls: ['./document-type-pro-forma.component.scss']
})
export class DocumentTypeProFormaComponent implements OnInit {

  @Input() document: DocumentHeader;
  public isMobile: boolean;

  constructor( private deviceService: DeviceDetectorService ) { }

 ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
   }


}
