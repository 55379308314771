import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

//Created
import { SubscriptionService } from '../../services/subscription.service';
import { ToastService } from '../../services/toast.service';
import { ToastType } from '../../core/enums';
import { AuthService } from '../../services/auth.service';
import { AppTranslationService } from '../../services/app-translation.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit {

  provider;
  submitted = false;
  status;
  currentUser;
  public loading = false;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private location: Location,
    private authService: AuthService,
    private translationService: AppTranslationService) { }

  gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

  async ngOnInit() {
    this.provider = history.state;

    this.loading = true;
    this.currentUser = this.authService.getCurrentUser();
    this.loading = false;

    //If someone refreshes this page and we have no data
    //Redirect him to the previous page
    if(this.provider.id === undefined){
      this.router.navigateByUrl('/provider');
    }else{
    //Redirect to subscription details if he comes here from profile update
    //The new logic is we ask the user enter his personal data as late as posible
    if (this.provider.redirectToSubscription === true) {
      let data = await this.AddSubscription(this.provider.id, true);
      this.router.navigateByUrl('/subscription/' + data.subscriptionId, { state: data });
    }else{
      this.status = true;
      if (this.provider.status == 2 || this.provider.status == 3) {
        this.status = false;
        }
      }
    }
  }

  async addNewSubscription() {

    if (this.provider.status.code == 2 || this.provider.status.code == 3) {

      let result = await this.AddSubscription(this.provider.id, false);
      if (result === true) {
        this.toastService.newToast( this.gT('providerPage.success'), this.gT('providerPage.successCreatingConnection' ), ToastType.Success);
      }
        this.router.navigateByUrl('/subscription');
    }
    else {

     if (this.currentUser.aduptod === "False") {
          this.provider.redirectToSubscription = true;
          this.router.navigateByUrl("/profile", { state: this.provider });
        }
        else
        {
          let data = await this.AddSubscription(this.provider.id, true);
          this.router.navigateByUrl('/subscription/' + data.id, { state: data });
        }
    }
  }

  async AddSubscription(providerId, returnProviderData) : Promise<any> {

    this.loading = true;
    let retVal = true as any;

    try {
      let result = await this.subscriptionService.AddNewSubscription(this.provider.id);

    if (returnProviderData == true) {
      retVal = result.data;
    }
  } catch (error) {
    retVal = false;
    this.toastService.newToast(this.gT('providerPage.error'), error.error.message, ToastType.Error);
  }

  this.loading = false;
  return new Promise<any>((resolve, reject) => {
     resolve(retVal);
  });
}

  cancel() {
    this.location.back();
  }

}
