import { Component, OnInit, Input } from '@angular/core';

//Created
import { DocumentHeader } from '../../models/document-header';

@Component({
  selector: '[app-document]',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  @Input() document: DocumentHeader;
  documentType: string;

  constructor() { }

  ngOnInit() {
    this.documentType = this.document.documentType;
  }

}
