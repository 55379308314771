import { Injectable } from "@angular/core";
import {
  CanLoad,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Route,
  Router,
} from "@angular/router";
import { Observable, from, of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkUser();
  }

  canLoad(state: Route): Observable<boolean> {
    return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    if (this.authService.isAuthenticated() == false) {
      this.router.navigate(["/login"]);
      return of(false);
    } else {
      return of(true);
    }
  }
}
