import { Component, OnInit } from "@angular/core";
import { DocumentHandlerService } from "src/app/services/document-handler.service";
import { DocumentHeader } from "src/app/models/document-header";
import { Router } from "@angular/router";
import { IPayPaymentService } from "src/app/services/ipay-payment.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "app-ipay-payment",
  templateUrl: "./ipay-payment.component.html",
  styleUrls: ["./ipay-payment.component.scss"],
})
export class IPayPaymentComponent implements OnInit {
  loading = false as boolean;
  selectedDocuments: DocumentHeader[] = [];
  public isMobile: boolean;
  documents: any;
  params: any;
  target:any;
  totalSum: number;

  constructor(
    private documentHandler: DocumentHandlerService,
    private router: Router,
    private paymentService: IPayPaymentService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.selectedDocuments = this.documentHandler.selectedDocuments;

    if (this.selectedDocuments.length == 0) {
      this.router.navigateByUrl("/");
    } else {
      let ids = this.documentHandler.selectedDocuments.map((a) => a.id);
      this.loading = true;
      // let response = this.paymentService
      //   .paymentPrepare(ids)
      //   .subscribe((res) => {
      //     console.log(res);
      //     this.documents = res["data"]["documents"];
      //     this.params = res["data"]["params"];
      //     this.target = res["data"]["target"];
      //     this.loading = false;

      //     //let totalSum = this.documents.map((doc)=> doc.calculation.totalTransactionAmount);

      //     this.totalSum = this.documents.reduce(
      //       (sum, doc) => sum + doc.calculation.totalTransactionAmount,
      //       0
      //     );
      //   });
    }
  }

  onPayButtonClick() {
    console.log(this.params);

    // let formData: any = new FormData();
    // formData.append("merchantUsername", this.params['merchantUsername']);
    // formData.append("merchantTransactionId", this.params['merchantTransactionId']);
    // formData.append("totalAmount", this.params['totalAmount']);
    // formData.append("timestamp", this.params['timestamp']);
    // formData.append("signature", this.params['signature']);
    // formData.append("errorPage", this.params['errorPage']);
    // formData.append("successPage", this.params['successPage']);


    // this.http.post(this.target, formData).subscribe(
    //   (response) => console.log(response),
    //   (error) => console.log(error)
    // )
  }
}
