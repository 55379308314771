import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//Created
import { ProvidersService } from '../../services/providers.service';
import { ToastService } from '../../services/toast.service';
import { ToastType } from '../../core/enums';
import { CurrentUserService } from '../../services/current-user.service';
import { AuthService } from '../../services/auth.service';
import { AppTranslationService } from '../../services/app-translation.service';



@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})

export class AddressComponent implements OnInit {

  loading = false as boolean;
  municipalities: any;
  selectedMunicipalityId = '' as string;

  addressForm: FormGroup;
  submitted = false;

  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postCode: string;
  municipalityId: string;
  city: string;

  result;
  addressData = null as any;
  showInfoCard = false;
  provider;

  user: any;

  constructor(private providersService: ProvidersService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private router: Router,
    private translationService: AppTranslationService) { }

  gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

  async ngOnInit() {

    this.addressForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      addressLine3: [''],
      postCode: ['', Validators.required],
      city: ['', Validators.required]
    });

    this.provider = history.state;

    this.user = this.authService.getCurrentUser();

    //Get address data
    let result = await this.currentUserService.getAddressData();
    this.addressData = result.data;
    if (this.provider.redirectToSubscription === true) {
      this.showInfoCard = true;
    }

    this.addressForm.patchValue({
      firstName: this.addressData.firstName,
      lastName: this.addressData.lastName,
      addressLine1: this.addressData.addressLine1,
      addressLine2: this.addressData.addressLine2,
      addressLine3: this.addressData.addressLine3,
      postCode: this.addressData.postCode,
      city: this.addressData.city
    })

    this.municipalities = await this.providersService.GetMunicipalityData(false);
    this.selectedMunicipalityId = this.addressData.municipalityId;
  }

  //---Convenience getter for easy access to form fields----
  get f() { return this.addressForm.controls; }

  async onSubmit() {
    this.submitted = true;
    this.loading = true;

    if (this.addressForm.invalid) {
      this.loading = false;
      return;
    }

    try {
      //We put the MunicipalityId in the same form object before save
      this.addressForm.value.municipalityId = this.selectedMunicipalityId;
      await this.currentUserService.updateAddress(this.addressForm.value).then(async (res) => {
        await this.authService.refreshToken();
        this.result = res;
      });

      this.loading = false;

      //Ako dolazi sa wizarda onda ga redirektujemo na veze nakon uspešnog čuvanja adrese
      if (this.provider.id !== undefined && this.result.status === 0 && this.user.aduptod === 'False') {
        this.toastService.newToast(this.gT('addressPage.success'), this.gT('addressPage.successMessage'), ToastType.Success);

        if (this.provider.urlFriendlyName !== undefined) {
          this.router.navigateByUrl("/provider/" + this.provider.urlFriendlyName, {
            state: this.provider
          });
        } else {
          this.router.navigateByUrl('/provider');
        }
      } else {
        this.toastService.newToast(this.gT('addressPage.success'), this.gT('addressPage.successMessage'), ToastType.Success);
      }
    }
    catch (error) {
      this.loading = false;
      this.toastService.newToast(this.gT('addressPage.error'), this.gT('addressPage.errorMessage'), ToastType.Error);
    }

  }

  cancel() {
    this.router.navigateByUrl('');

  }
}
