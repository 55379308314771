import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

//created
import { ToastService } from "../../services/toast.service";
import { ToastType } from "../../core/enums";
import { SubscriptionService } from "../../services/subscription.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { DeleteSubModalComponent } from "../../components/delete-sub-modal/delete-sub-modal.component";

@Component({
  selector: "app-subscription-details",
  templateUrl: "./subscription-details.component.html",
  styleUrls: ["./subscription-details.component.scss"],
})
export class SubscriptionDetailsComponent implements OnInit {
  subscription;
  submitted = false;
  newSubscriptionForm: FormGroup;
  data;
  isContractIdEmpty = true;
  isPersonalIdEmpty = true;
  loading = false as boolean;
  accessTypeDescription;
  entireSubscription;
  subscriptionType = null as string;
  validStatus = true;
  imageUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private location: Location,
    private translationService: AppTranslationService,
    private modalService: NgbModal
  ) {}

  setSubscriptionType(value) {
    this.subscriptionType = value;
  }

  gT = (key: string | Array<string>, interpolateParams?: Object) =>
    this.translationService.getTranslation(key, interpolateParams);

  ngOnInit() {
    this.loading = true;

    this.newSubscriptionForm = this.formBuilder.group({
      ContractId: ["", Validators.required],
      PersonalId: [""],
      SecundaryId: [""],
    });

    // If we dont have passed data throu url we return user to previous page
    if (history.state.id === undefined) {
      this.loading = false;
      this.router.navigateByUrl("/subscription");
    } else {
      this.subscription = history.state;
      this.accessTypeDescription = this.subscription.access.description;
      //this.entireSubscription = this.subscription.entireSubscription;

      //prikazivanje slike
      let image = this.subscription.provider.helper;
      if (image == null) {
        this.imageUrl = "";
      } else {
        this.imageUrl = image;
      }

      //provere za disable stranice
      if (this.subscription.contractNumber != null) {
        this.isContractIdEmpty = false;
        this.newSubscriptionForm.controls["ContractId"].disable();
        this.newSubscriptionForm.controls["PersonalId"].disable();
        this.newSubscriptionForm.controls["SecundaryId"].disable();
      }
      if (this.subscription.personalId == null) {
        this.isPersonalIdEmpty = false;
      }
      if (
        this.subscription.subscriptionStatus.code == 1 &&
        this.subscription.provider.status.code != 4
      )
        if (
          this.subscription.contractNumber == null &&
          this.subscription.provider.status.code != 4
        ) {
          this.validStatus = false;
          this.newSubscriptionForm.controls["ContractId"].disable();
          this.newSubscriptionForm.controls["PersonalId"].disable();
          this.newSubscriptionForm.controls["SecundaryId"].disable();
        }

      this.newSubscriptionForm.patchValue({
        ContractId: this.subscription.contractNumber,
        PersonalId: this.subscription.personalId,
      });

      this.loading = false;
    }
  }

  get f() {
    return this.newSubscriptionForm.controls;
  }

  deleteSubscription(id) {
    const modalRef = this.modalService.open(DeleteSubModalComponent, {
      backdrop: "static",
      centered: true,
      size: "sm",
    });
    modalRef.componentInstance.id = id;

    modalRef.result.then((result) => {
      if (result !== null) {
        if (result === true) {
          this.toastService.newToast(
            this.gT("subscriptionDetailsPage.success"),
            this.gT("subscriptionDetailsPage.successDeleteMessage"),
            ToastType.Success
          );
          this.router.navigateByUrl("/subscription");
        } else {
          this.toastService.newToast(
            this.gT("subscriptionDetailsPage.error"),
            result.error.message,
            ToastType.Error
          );
        }
      }
    });
  }

  cancel() {
    this.router.navigateByUrl("/subscription");
  }

  async onSubmit(newSubscriptionForm) {
    this.loading = true;
    this.submitted = true;

    if (
      this.newSubscriptionForm.invalid ||
      this.subscriptionType == undefined
    ) {
      let message = this.gT("subscriptionDetailsPage.validationMessage");

      if (
        this.subscriptionType == undefined &&
        !this.newSubscriptionForm.invalid
      ) {
        message = this.gT("subscriptionDetailsPage.validationMessage2");
      }

      this.toastService.newToast(
        this.gT("subscriptionDetailsPage.error"),
        message,
        ToastType.Error
      );
      this.loading = false;
      return;
    }

    //Zato sto su polja disabled, vrednosti iz forme se moraju uzeti preko getRawValues
    let formRawValues = newSubscriptionForm.getRawValue();
    console.log(formRawValues);

    try {
      this.subscription.contractId = this.newSubscriptionForm.value.ContractId;
      let SubscriptionType = this.subscriptionType;

      let data = {
        providerId: this.subscription.provider.id,
        contractId: formRawValues.ContractId,
        personalId: formRawValues.PersonalId,
        secondaryId: formRawValues.SecundaryId,
        subscriptionId: this.subscription.id,
        subscriptionType: parseInt(SubscriptionType),
      };

      let result = await this.subscriptionService.UpdateSubscription(data);
      this.loading = false;
      this.toastService.newToast(
        this.gT("subscriptionDetailsPage.success"),
        this.gT("subscriptionDetailsPage.successMessage"),
        ToastType.Success
      );
      this.router.navigateByUrl("/subscription");
    } catch (error) {
      this.loading = false;
      this.toastService.newToast(
        this.gT("subscriptionDetailsPage.error"),
        error.error.message,
        ToastType.Error
      );
    }
  }

  public getWidgetIconClass(subscription) {
    if (subscription.provider.status <= 2)
      return "fa fa-exclamation-triangle custom-yellow-icon";

    if (subscription.shared == undefined || subscription.shared.length === 0) {
      if (subscription.accountStatus.code === 4) return "fa fa-times-circle";
      if (
        subscription.subscriptionStatus.code === 1 &&
        subscription.provider.status === 4
      )
        return "fa fa-question";
      if (
        subscription.subscriptionStatus.code === 3 &&
        subscription.access.accessCode === 1
      )
        return "fa fa-link";
      if (subscription.subscriptionStatus.code === 2)
        return "fa fa-chain-broken";

      if (subscription.access.code === 2) {
        if (subscription.accountStatus.code === 1) return "fa fa-chain-broken";
        if (subscription.accountStatus.code === 2) return "fa fa-link";
        if (subscription.accountStatus.code === 4) return "fa fa-times-circle";
      }
    }

    if (subscription.shared != undefined && subscription.shared.length > 0)
      return "fa fa-users";

    return "fa fa-bookmark";
  }

  public getClass(subscription) {
    if (
      subscription.provider.status === 1 ||
      subscription.provider.status === 2
    ) {
      return "info-box-icon bg-gray";
    } else if (subscription.provider.status === 3) {
      return "info-box-icon bg-warning";
    } else {
      if (
        subscription.subscriptionStatus.statusCode === 1 ||
        subscription.accountStatus.statusCode === 4
      ) {
        return "info-box-icon bg-red";
      } else if (
        subscription.subscriptionStatus.statusCode === 2 ||
        subscription.accountStatus.statusCode === 1
      ) {
        return "info-box-icon bg-teal-active";
      } else if (subscription.accountStatus.statusCode === 2) {
        return "info-box-icon bg-green";
      }
      return "info-box-icon bg-warning";
    }
  }

  public canDelete(subscription) {
    console.log("disabled");

    // (!isContractIdEmpty)
    if (subscription.subscriptionStatus.code == 1) {
      if (subscription.accountStatus.code == 4) {
        //veza odbijena
        return false;
      }
      else if (subscription.accountStatus.code == 1)
      {
        if (!this.isContractIdEmpty) {
          return true
        }
        return false;
      }
    } else if (subscription.subscriptionStatus.code == 3) {
      return true;
    }

    if (!this.isContractIdEmpty) {
      return true;
    }

    return true;
  }
}
