import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject,
} from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

//Created
import { ConnectionService } from "../../services/connection.service";
import { DocumentService } from "../../services/document.service";
import { HttpParam } from "../../models/http-param";
import { DocumentHandlerService } from "src/app/services/document-handler.service";
import { IViewBag } from "src/app/repo/interface/iview-bag";
import { Router } from "@angular/router";
import { IPayPaymentService } from "src/app/services/ipay-payment.service";
import { CordovaService } from "src/app/services/cordova.service";
import { DeviceDetectorService } from "ngx-device-detector";

const HTTP_PARAMS = "HTTP_PARAMS";

@Component({
  selector: "app-bill-filters",
  templateUrl: "./bill-filters.component.html",
  styleUrls: ["./bill-filters.component.scss"],
})
export class BillFiltersComponent implements OnInit {
  @Output() onDocumentSearch = new EventEmitter();
  @Output() onDocumentsLoading = new EventEmitter();
  @Input() isMobile: boolean;
  @Input() currentPage: number;
  public loading: boolean = false as boolean;
  //public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showSearchResult: boolean = false as boolean;

  //--------DATUMI---------------
  //---NE KORISTI SE ZASAD-------
  dateFromModal: NgbDateStruct = null;
  dateFrom: { year: number; month: number };
  selectedDocuments: number;
  dateToModal: NgbDateStruct = null;
  dateTo: { year: number; month: number };

  public isCordova: boolean;

  constructor(
    private connectionService: ConnectionService,
    private documentService: DocumentService,
    private documentHandler: DocumentHandlerService,
    private paymentService: IPayPaymentService,
    private cordovaService: CordovaService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    @Inject("API_URL") private API_URL: string,
    @Inject("ADMIN_PANEL_URL") private ADMIN_PANEL_URL: string
  ) {}

  public providers: any;
  public httpParams: HttpParams = new HttpParams();
  public params: Array<HttpParam> = new Array<HttpParam>();
  private showProviderFilter: boolean = false;
  private showMonthFilter: boolean = false;

  private isCollapsed = true;

  ngOnInit() {
    this.isCordova = this.cordovaService.onCordova as boolean;
    this.isMobile = this.deviceService.isMobile();

    console.log("Is on cordova: " + this.isCordova);
    console.log("Is on mobile: " + this.isMobile);
  }

  public init() {
    if (sessionStorage.getItem(HTTP_PARAMS) !== null) {
      this.params = JSON.parse(sessionStorage.getItem(HTTP_PARAMS));

      this.params.forEach((p) => {
        this.httpParams = this.httpParams.set(p.name, p.value);
      });
      this.getDocuments();
    } else {
      let page: HttpParam = new HttpParam({
        name: "page",
        text: "",
        value: String(this.currentPage),
        showInList: false,
      });

      this.setFilter(page);
    }

    this.showCompanyFilter();

    this.documentHandler.onDocumentSelected.subscribe((docs) => {
      this.selectedDocuments = docs.length;
    });

    //console.log(this.cordovaService.);
  }

  onPayMarked() {
    this.loading = true;

    this.paymentService.wantToPay(
      this.documentHandler.selectedDocuments,
      (a) => {
        this.loading = false;
      }
    );
  }

  onClearMarked() {
    console.log("clear all");

    this.documentHandler.clearAll();
  }

  showCompanyFilter() {
    this.connectionService.GetSubscriptions().then((subs) => {
      //pravimo distinct i onda pravimo novi objekat

      const map = new Map();
      this.providers = [];
      for (const p of subs.data) {
        if (!map.has(p.provider.id)) {
          map.set(p.provider.id, true);
          this.providers.push({
            id: p.provider.id,
            name: p.provider.name,
            selected: false,
          });
        }
      }

      this.showProviderFilter = true;
    });
  }

  public getDocuments(appendToList = false as boolean) {
    this.loading = true;

    this.documentService.getDocuments(this.httpParams).then(
      (documents) => {
        //console.log(documents);
        let retval = { appendToList: appendToList, data: documents };
        if (documents.documents.length == 0) {
          this.showSearchResult = true;
        }
        this.onDocumentSearch.emit(retval);
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  onProviderSelected(provider: any) {
    let param: HttpParam = new HttpParam({
      name: "provider",
      text: provider.name,
      value: provider.id,
      showInList: true,
    });
    this.resetPageFilter();
    this.setFilter(param);
  }

  searchByProviders() {
    const selected = this.providers.filter(
      (provider) => provider.selected == true
    );
    //console.log(selected);
  }

  onPaymentSelect(status: boolean) {
    let text = status ? "Placeno" : "Nije placeno";

    let param: HttpParam = new HttpParam({
      name: "paid",
      text: text,
      value: String(status),
      showInList: true,
    });

    this.resetPageFilter();
    this.setFilter(param);
  }

  onClearFilters() {
    sessionStorage.removeItem(HTTP_PARAMS);
    this.currentPage = 0;
    this.params = new Array<HttpParam>();
    this.httpParams = new HttpParams();
    let page: HttpParam = new HttpParam({
      name: "page",
      text: "",
      value: String(0),
      showInList: false,
    });

    this.setFilter(page);
  }

  public setFilter(param: HttpParam, appendToList = false as boolean) {
    let _param = this.params.find((p) => p.name === param.name);
    if (_param) {
      //update
      this.removeFilter(_param);
      this.params.push(param);
    } else {
      //add
      this.params.push(param);
    }

    this.httpParams = this.httpParams.set(param.name, param.value);
    sessionStorage.setItem(HTTP_PARAMS, JSON.stringify(this.params));
    //console.log(JSON.stringify(this.params));

    this.getDocuments(appendToList);
  }

  private resetPageFilter() {
    let _page = this.params.find((p) => p.name === "page");

    let page: HttpParam = new HttpParam({
      name: "page",
      text: "",
      value: String(0),
      showInList: false,
    });

    this.params[this.params.indexOf(_page)] = page;
    this.currentPage = 0;
    this.httpParams = this.httpParams.set(page.name, page.value);
    sessionStorage.setItem(HTTP_PARAMS, JSON.stringify(this.params));
    //console.log(JSON.stringify(this.params));
  }

  public removeFilter(param: HttpParam, forceRefresh: boolean = false) {
    const index = this.params.indexOf(param);
    if (index > -1) {
      this.params.splice(index, 1);
      sessionStorage.setItem(HTTP_PARAMS, JSON.stringify(this.params));

      if (forceRefresh) {
        this.currentPage = 0;
        this.httpParams = new HttpParams();
        this.params.forEach((p) => {
          this.httpParams = this.httpParams.set(p.name, p.value);
        });

        // this.loading = true;
        // this.onDocumentSearch.emit(this.loading);
        this.getDocuments();
      }
    }
  }

  saveFilterToLocalStrorige() {
    console.log(JSON.stringify(this.httpParams));
    const paramsArray = this.httpParams
      .keys()
      .map((x) => ({ [x]: this.httpParams.get(x) }));

    sessionStorage.setItem(HTTP_PARAMS, JSON.stringify(paramsArray));
  }

  //Za neka buduca vremena
  // onDateFromSelected($event) {
  //   this.httpParams = this.httpParams.set("from", this.toFilterDate($event));
  //   this.saveFilterToLocalStrorige();

  //   this.documentService.getDocuments(this.httpParams).then((documents) => {
  //     this.onDocumentSearch.emit(documents);
  //   });
  // }

  // onDateToSelected($event) {
  //   this.httpParams = this.httpParams.set("to", this.toFilterDate($event));
  //   this.saveFilterToLocalStrorige();

  //   this.documentService.getDocuments(this.httpParams).then((documents) => {
  //     this.onDocumentSearch.emit(documents);
  //   });
  // }

  // private toFilterDate($event): string {
  //   let month: string;
  //   if ($event.next.month < 10) {
  //     month = `0${$event.next.month}`;
  //   }
  //   console.log(month);
  //   let dateFilter: string = `${$event.next.year}-${month}-01`;

  //   return dateFilter;
  // }
}
