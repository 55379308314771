import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Created
import { ConnectionService } from '../../services/connection.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  subscriptions: any;
  suggestedSubscriptions: any;
  loading = false as boolean;
  user: any;

  constructor(private connectionService: ConnectionService,
    private router: Router,
    private authService: AuthService) { }

  async ngOnInit() {
    this.loading = true;
    this.user = this.authService.getCurrentUser();
    let allSubscriptions = await this.connectionService.GetSubscriptions();
    //this.subscriptions = allSubscriptions.data.subscriptions;
    this.subscriptions = allSubscriptions.data;

    let suggestions = await this.connectionService.GetSuggestedSubscriptions();
    this.suggestedSubscriptions = suggestions.data;
    this.loading = false;
  }

  selectSubscription(subscription) {

    let sharedSubsData = [] as any;

    if (subscription.shared !== undefined && subscription.shared.length > 0) {
        sharedSubsData = subscription.shared;
    }

    this.router.navigateByUrl('/subscription/' + subscription.id, { state: subscription });
  }

  goToDetails(suggestion) {
    this.router.navigateByUrl('/provider/'+ suggestion.urlFriendlyName , { state: suggestion });
  }

 public getWidgetIconClass(subscription) {
    if (subscription.provider.status === 1 || subscription.provider.status === 2) {
      return 'fa fa-exclamation-triangle custom-yellow-icon fa-5x notDefined';
    } else if (subscription.provider.status === 3) {

      return 'fa fa-bookmark fa-5x warning';

    } else {
      if (subscription.subscriptionStatus.code === 1 ||
        subscription.accountStatus.code === 4) {

        return 'fa fa-question fa-5x danger';

      } else if (subscription.subscriptionStatus.code === 2 ||
        subscription.accountStatus.code === 1) {

        return 'fa fa-chain-broken fa-5x';

      } else if (subscription.accountStatus.code === 2) {
        if(subscription.shared.length > 0){
          return 'fa fa-users fa-5x active';
        }
        return 'fa fa-link fa-5x active';

      }
      return 'fa fa-bookmark fa-5x warning';
    }
  }
}
