import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  baseUrl = `${this.apiUrl}/subscription`;

  constructor(
    @Inject("API_URL") private apiUrl: string,
    private http: HttpClient) { }


  public async GetSubscriptions(): Promise<any> {
    // let params = new HttpParams()
    //   .set("", data);

    return await this.http
      .get<any>(this.baseUrl)
      .toPromise();
  }

  public async GetSuggestedSubscriptions(): Promise<any> {
    return await this.http
      .get<any>(this.baseUrl + '/suggestions')
      .toPromise();
  }
}
