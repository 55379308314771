import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DocumentHeader } from "../models/document-header";
import { AppTranslationService } from "./app-translation.service";
import { ToastService } from "./toast.service";
import { ToastType } from "../core/enums";

@Injectable({
  providedIn: "root",
})
export class IPayPaymentService {
  constructor(
    private http: HttpClient,
    private translationService: AppTranslationService,
    private toastService: ToastService,
    @Inject("API_URL") private API_URL: string
  ) {}

  gT = (key: string | Array<string>, interpolateParams?: Object) =>
    this.translationService.getTranslation(key, interpolateParams);

  baseUrl = `${this.API_URL}/document`;

  wantToPay(selectedDocuments: DocumentHeader[], callback: Function) {
    let ids = selectedDocuments.map((a) => a.id);

    let response = this.checkoutVerify(ids).subscribe(
      (response) => {
        console.log(response);

        let verify = response["data"];

        if (verify !== null) {
          if (verify["success"] === true) {
            let url = `${this.API_URL}/document/checkout`;

            let id = verify["id"];
            url = `${url}?id=${id}`;

            console.log(url);

            window.location.href = url;
          } else {
            if (typeof callback === "function") {
              callback();
            }
          }
        } else {
          if (typeof callback === "function") {
            callback();
          }
        }
      },
      (error) => {
        console.log(error);
        //error.error.message
        this.toastService.newToast(
          this.gT("subscriptionDetailsPage.error"),
          error.error.message,
          ToastType.Error
        );

        if (typeof callback === "function") {
          callback();
        }
      }
    );
  }

  //Prvo na API proveravamo da li je sve ok, a tek nakon toga korisnika redirektujemo na AppAdmin
  checkoutVerify(ids: string[]) {
    //let url = `${this.baseUrl}/view/paymentform?checkoutid=${checkoutid}&invoiceid=${invoiceid}`;
    let url = `${this.baseUrl}/checkout/verify?ids=${ids}`;
    //console.log("Verfy: " + url);

    return this.http.get(url);
  }

  getTransactionInfo(id: string) {
    let url = `${this.API_URL}/payment/status/${id}`;
    return this.http.get(url);
  }

  getTransactionDetails(documentId: string) {
    let url = `${this.API_URL}/payment/details/${documentId}`;
    return this.http.get(url);
  }
}
