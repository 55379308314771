import { Component, OnInit, NgZone } from "@angular/core";
import { Router } from '@angular/router';

//Created
import { AuthService } from '../../services/auth.service';
import { TokenManager } from '../../services/app-auth/tokenManager';
import { CordovaService } from '../../services/cordova.service';

@Component({
  selector: "app-signout-redirect-callback-component",
  templateUrl: "./signout-redirect-callback-component.component.html",
  styleUrls: ["./signout-redirect-callback-component.component.scss"]
})
export class SignoutRedirectCallbackComponent implements OnInit {

  constructor(private tokenManager: TokenManager,
    private auth: AuthService,
    private cordovaService: CordovaService,
    private router: Router,
    private zone: NgZone,) {}

  ngOnInit() {
    this.tokenManager.clearToken();

    console.log('token clear');

    if (this.cordovaService.onCordova) {
      this.tokenManager.clearToken();
      (<any>window).navigator.app.exitApp();
      //this.zone.run(() => { this.router.navigate(["/"]); });
    } else {
      //this.auth.signin();
      this.router.navigate(["/"]);
    }
  }
}
