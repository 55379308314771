import { Component, OnInit, Input, Inject, NgZone, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";

//Created
import { CordovaService } from "../../services/cordova.service";
import { DocumentService } from "../../services/document.service";
import { ViewBagOrigin, ToastType } from "../../core/enums";
import { IViewBag } from "../../repo/interface/iview-bag";
import { PdfService } from "../../services/pdf.service";
import { FileManagerService } from "src/app/services/file-manager.service";
import { ToastService } from "../../services/toast.service";
import { DocumentHandlerService } from "src/app/services/document-handler.service";
import { IPayPaymentService } from "src/app/services/ipay-payment.service";
import { DocumentHeader } from "src/app/models/document-header";

@Component({
  selector: "document-buttons",
  templateUrl: "./document-buttons.component.html",
  styleUrls: ["./document-buttons.component.scss"],
})
export class DocumentButtonsComponent implements OnInit {
  @Input() document: any;
  @Input() showPreviewButton: boolean;

  @Output() onTransactionGet: EventEmitter<any> = new EventEmitter();

  private paymentSlipURI: string = null;
  private fullBillURI: string = null;

  public isMobile = false as boolean;
  public isCordova = false as boolean;
  loading = false as boolean;

  blob: Blob;

  constructor(
    private router: Router,
    private documentService: DocumentService,
    private deviceService: DeviceDetectorService,
    private cordovaService: CordovaService,
    private fileManager: FileManagerService,
    private pdfService: PdfService,
    private toastService: ToastService,
    private zone: NgZone,
    private paymentService: IPayPaymentService,
    private documentHandler: DocumentHandlerService,
    @Inject("API_URL") private apiUrl: string,
    @Inject("IS_PAYMENT_ENABLED") public IS_PAYMENT_ENABLED: string
  ) {}

  ngOnInit() {
    //console.log(this.document);

    this.paymentSlipURI = `${this.apiUrl}/pdf/invoice/${this.document.id}`; //"/pdf/invoice/" + this.document.id;
    this.fullBillURI = `${this.apiUrl}/pdf/invoice/full/${this.document.id}`; //"/pdf/invoice/" + this.document.id;

    this.isMobile = this.deviceService.isMobile();
    this.isCordova = this.cordovaService.onCordova as boolean;
  }

  onButtonPayClick() {
    this.documentHandler.addSelectedDocument(this.document);

    let _this = this;
    _this.loading = true;
    this.paymentService.wantToPay(
      this.documentHandler.selectedDocuments,
      (a) => {
        _this.zone.run(() => {
          //TODO to translate
          _this.toastService.newToast(
            "Greška",
            "Trenutno nije moguće izvršiti online plaćanje!",
            ToastType.Error
          );
        });
        _this.loading = false;
      }
    );
  }

  onButtonManualPayClick() {
    this.router.navigateByUrl(
      "/document/" + this.document.id + "/mark-as-paid"
    );
  }

  getTransactionDetails(document: DocumentHeader) {
    console.log(document);
    this.paymentService.getTransactionDetails(document.id).subscribe((res)=>{
      this.onTransactionGet.emit(res["data"]);
    });
  }

  // NEKORISTIMO VIŠE
  showPdfDocument(url: string) {
    this.documentService.downloadPDF(url).subscribe((res) => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, "_blank");
    });
  }

  printPdfDocument() {
    let url = `${this.apiUrl}/pdf/document/${this.document.id}`;

    this.documentService.downloadPDF(url).subscribe((res) => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, "_blank");
    });
  }

  goToPreview() {
    this.router.navigateByUrl("/document/" + this.document.id + "/preview");
  }

  downloadWebMobile() {
    this.pdfService.getPdf(this.document.id).subscribe((data) => {
      this.blob = new Blob([data as any], { type: "application/pdf" });

      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement("a");
      link.href = downloadURL;
      link.download = this.document.id + ".pdf";
      link.click();
    });
  }

  downloadCordovaMobile() {
    let url = `${this.apiUrl}/pdf/document/${this.document.id}`;

    let fileName =
      "Racun_" +
      this.document.provider.urlFriendlyName +
      "_" +
      this.document.id +
      ".pdf";
    // const destination = this.file.externalDataDirectory + '/download/' + fileName + '.pdf';
    console.log(url);
    console.log(fileName);

    let _this = this;
    _this.loading = true;
    this.fileManager.downloadFile(url, fileName, function (result) {
      console.log(result);

      if (result.status == 0) {
        _this.loading = false;
      } else {
        _this.zone.run(() => {
          //TODO to translate
          _this.toastService.newToast(
            "Greška",
            "Ne mogu da preuzmem račun!",
            ToastType.Error
          );
        });
      }
    });
  }
}
