import { Injectable } from "@angular/core";
import { CordovaService } from "./cordova.service";
import { ToastService } from "./toast.service";
import { ToastType } from "../core/enums";
import { TokenManager } from "./app-auth/tokenManager";

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: "root",
})
export class FileManagerService {
  constructor(
    private cordovaService: CordovaService,
    private tokenManager: TokenManager
  ) {}

  downloadFile(fileUri: string, fileName: string, callback = null) {
    let filePath = this.externalRootDirectory + "download/";
    console.log(filePath);

    let fullFilePath = filePath + fileName;
    console.log(fullFilePath);

    const _this = this;

    //prvo trazimo fajl ako ga ima na lokalu
    (<any>window).resolveLocalFileSystemURL(fullFilePath, function (dirEntry) {
        console.log(dirEntry);
        _this.openFile(dirEntry.nativeURL);
      },
      function (err) {
        console.log(err);

        //ako fajl ne postoji, treba download
        if (err.code == 1) {
          (<any>window).resolveLocalFileSystemURL(
            filePath,
            function (dirEntry) {
              console.log(dirEntry);
              _this.createFile(fileUri, dirEntry, fileName, false, callback);
            },
            function (err) {
              console.log(err);
            }
          );
        }
      }
    );
  }

  openFile(fileUri: string) {
    _window().cordova.plugins.fileOpener2.open(fileUri, "application/pdf", {
      error: function (e) {
        console.log(
          "Error status: " + e.status + " - Error message: " + e.message
        );
      },
      success: function () {
        console.log("file opened successfully");
      },
    });
  }

  createFile(fileUri, dirEntry: any, fileName, isAppend, callback) {
    const _this = this;
    var oReq = new XMLHttpRequest();
    // Make sure you add the domain name to the Content-Security-Policy <meta> element.
    oReq.open("GET", fileUri, true);
    // Define how you want the XHR data to come back
    oReq.responseType = "blob";

    let accessToken = _this.tokenManager.getToken().accessToken;
    oReq.setRequestHeader("Authorization", "Bearer " + accessToken);

    oReq.onerror = function (err) {
      console.log(err);
    };

    oReq.onload = function (oEvent) {
      var blob = oReq.response; // Note: not oReq.responseText
      console.log(blob);

      //console.log(oEvent.currentTarget.status);

      if (blob.size > 0) {
        dirEntry.getFile(
          fileName,
          { create: true, exclusive: false },
          function (fileEntry) {
            fileEntry.createWriter(function (fileWriter) {
              fileWriter.onwriteend = function () {
                console.log("Successful file write...!");

                _this.openFile(fileEntry.nativeURL);

                // if (typeof callback === "function") {
                //   //callback(fileEntry);
                //   callback({ status: 0, fileNetry: fileEntry });
                // }
              };

              fileWriter.write(blob);
            });
          },
          function (err) {
            console.log(err);
          }
        );
      } else {
        //nesto nije dobro

        callback({ status: 1 });
      }
    };

    oReq.send(null);
  }

  get applicationStorageDirectory(): string {
    if (this.onCordova) {
      return _window().cordova.file.applicationStorageDirectory;
    }
    return null;
  }

  get onCordova(): Boolean {
    return !!_window().cordova;
  }

  get externalRootDirectory(): string {
    if (this.onCordova) {
      return _window().cordova.file.externalRootDirectory;
    }
    return null;
  }
}
