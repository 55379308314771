import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//Created
import { ProvidersService } from '../../services/providers.service';
import { ToastService } from '../../services/toast.service';
import { ToastType } from '../../core/enums';
import { AppTranslationService } from '../../services/app-translation.service';

@Component({
  selector: 'app-new-provider',
  templateUrl: './new-provider.component.html',
  styleUrls: ['./new-provider.component.scss']
})
export class NewProviderComponent implements OnInit {

  newCompanyForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private providersService: ProvidersService,
    private toastService: ToastService,
    private translationService: AppTranslationService) { }

  gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

  ngOnInit() {
    this.newCompanyForm = this.formBuilder.group({
      name: ['', Validators.required],
      addressline1: [''],
      postcode: ['', Validators.required],
      city: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.newCompanyForm.controls; }

  async onSubmit() {
    this.submitted = true;

    if (this.newCompanyForm.invalid) {
      return;
    }

    try {
      await this.providersService.AddNewProvider(this.newCompanyForm.value);
      this.toastService.newToast(this.gT('newProviderPage.success'), this.gT('newProviderPage.successMessage'), ToastType.Success);
      this.router.navigateByUrl('/provider');
    } catch (error) {
      this.toastService.newToast(this.gT('newProviderPage.error'), this.gT('newProviderPage.errorMessage'), ToastType.Error);
    }

    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.newCompanyForm.value))
  }


  cancel() {
    this.router.navigateByUrl('/provider');
  }


}
