import { DefaultCrypto } from '@openid/appauth';
import { by } from 'protractor';


export interface EndSessionRequestJson {
  idTokenHint: string;
  postLogoutRedirectURI: string;
  state?: string;
}

const BYTES_LENGTH = 10;

export class EndSessionRequest {

  state: string;
  generateRandom = new DefaultCrypto();
  constructor(
    public idTokenHint: string,
    public postLogoutRedirectURI: string,
    state?: string) {
      console.log('EndSessionRequest');

      this.state = state || this.generateRandom.generateRandom(BYTES_LENGTH);
    }

  toJson(): EndSessionRequestJson {
    let json: EndSessionRequestJson = {idTokenHint: this.idTokenHint, postLogoutRedirectURI : this.postLogoutRedirectURI };

    if (this.state) {
      json['state'] = this.state;
    }

    return json;
  }

  static fromJson(input: EndSessionRequestJson): EndSessionRequest {
    return new EndSessionRequest(
        input.idTokenHint, input.postLogoutRedirectURI, input.state);
  }
}
