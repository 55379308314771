export class ContractOwner {
    constructor(body: ContractOwner) {
      this.id = body.id;
      this.fullName = body.fullName;
      this.addressLine1 = body.addressLine1;
      this.addressLine2 = body.addressLine2;
      this.addressLine3 = body.addressLine3;
      this.postCode = body.postCode;
      this.city = body.city;
    }
  
    id: string;
    fullName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postCode: string;
    city: string;
  }
  