import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceDetectorService } from "ngx-device-detector";

//Created
import { AuthService } from "../../services/auth.service";
import { DocumentHeader } from "../../models/document-header";
import { DocumentService } from "../../services/document.service";
import { BillFiltersComponent } from "../../components/bill-filters/bill-filters.component";
import { HttpParam } from "../../models/http-param";
import { FcmService } from "src/app/services/fcm.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  public documents: DocumentHeader[] = [];
  currentPage: number = 0;
  public totalCount: number = 0;
  public loading: boolean = false;
  public showSearchResult: boolean = false as boolean;
  providerName = "" as string;

  isMobile: boolean;
  currentUser = null as any;


  @ViewChild(BillFiltersComponent, { static: false })
  filters: BillFiltersComponent;

  constructor(
    private authService: AuthService,
    private documentService: DocumentService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private modalService: NgbModal
  ) {}

  ngAfterViewInit(): void {
    //this.showSearchResult = this.filters.showSearchResult;

    //this.loading = true;

    setTimeout(() => {
      this.filters.init();
    });
  }

  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();

    this.activatedRoute.url.subscribe((params) => {
      if (params.length > 0) {
        this.providerName = params[2].path;
      }
    });
    this.isMobile = this.deviceService.isMobile();
  }

  onShowNextPageButtonClick() {
    this.filters.currentPage = this.filters.currentPage + 1;

    let page: HttpParam = new HttpParam({
      name: "page",
      text: "",
      value: String(this.filters.currentPage),
      showInList: false,
    });

    this.filters.setFilter(page, true);
  }

  canGetNextPage() {
    return this.documents.length >= this.totalCount;
  }

  onDocumentSearch($event) {

    this.totalCount = $event.data.total;
    if ($event.data.documents.length == 0) {
      this.showSearchResult = true;
    }
    if ($event.appendToList == true) {
      this.documents.push(...$event.data.documents);
    } else {
      this.documents = $event.data.documents;
    }
    this.loading = false;
  }
}
