import { Injectable, OnDestroy, Inject } from "@angular/core";
import { User } from "../models/user.model";
import { Subject, BehaviorSubject } from "rxjs";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CurrentUserService implements OnDestroy {
  //public CurrentUser: User = null;
  public CurrentUser: Subject<User> = new Subject();
  public isAuthorized: BehaviorSubject<boolean> = new BehaviorSubject(false);

  baseUrl = `${this.apiUrl}/user`;

  constructor(
    @Inject("API_URL") private apiUrl: string,
    private http: HttpClient) {}

  public setCurrentUserData(userData: any) {
    console.log("User Data: " + JSON.stringify(userData));
  }

  public async updateAddress(data) : Promise<any> {
    
    let url = this.baseUrl + '/address';
    return await this.http.post<any>(url, data).toPromise();
   }

   public async getAddressData() : Promise<any> {

    let url = this.baseUrl + '/address';
    return await this.http.get<any>(url).toPromise();
   }

   public async generatePlativooId() : Promise<any> {

    let url = this.baseUrl + '/plativoo-id';
    return await this.http.get<any>(url).toPromise();
   }

   public async searchUsersByUid(uid) : Promise<any> {

    let url = `${this.baseUrl}/search/${uid}`;
    return await this.http.get<any>(url).toPromise();
   }




  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }
}
