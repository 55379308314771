import { Http, Response, ResponseContentType } from "@angular/http";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { pipe, forkJoin, Observable } from "rxjs";
import { DocumentHeader } from "../models/document-header";
import { BaseDocument } from "../models/base-document";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable()
export class DocumentService {
  baseUrl = `${this.apiUrl}/invoice`;

  private documents: DocumentHeader[] = [];

  constructor(
    @Inject("API_URL") private apiUrl: string,
    private http: HttpClient,
    private authService: AuthService,
    private deviceService: DeviceDetectorService
  ) {}

  // getInvoices(currentPage: number, forceLoad: boolean): Invoices[] {
  //     let response: any = [];
  //     if (this.invoices == null) {

  //     }
  //     if (forceLoad) {
  //         response = this.getInvoicesFromApi(currentPage);
  //     }

  //     return this.invoices.slice();
  // }

  async getDocuments(params: HttpParams): Promise<any> {
    var url = this.baseUrl;
    let queryParams = params.toString();

    url = url + '?' + queryParams;
    //console.log(url);

    return await this.http
      .get<any>(url)
      .pipe(
        map((response) => {
          //Ne vraća se više data.body
          let retval = {total: response.totalCount, documents: response.data.map((data) => new DocumentHeader(data))}
          return retval;
        })
      )
      .toPromise();
  }

  async getDocumentsFromApi(
    currentPage: number,
    providerName: string
  ): Promise<DocumentHeader[]> {
    var url = this.baseUrl;
    url = url + "?page=" + currentPage + "&providername=" + providerName;

    return await this.http
      .get<any>(url)
      .pipe(
        map((response) => {
          //console.log(response);
          //Ne vraća se više data.body
          return response.data.map((data) => new DocumentHeader(data));
        })
      )
      .toPromise();
  }

  getDocumentTemplatePreview(invoiceId: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "text/plain");
    headers = headers.append("Accept", "text/plain");
    const httpOptions = {
      headers: headers,
    };
    let url = `${this.baseUrl}/${invoiceId}.tmp.html`; //{id}.tmp.html

    return this.http.get(url, { responseType: "text" }).pipe(
      map(
        (response) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getDocumentGenericPreview() {}

  async getDocumentById(invoiceId: string): Promise<any> {
    let url = `${this.baseUrl}/${invoiceId}`;

    return this.http
      .get(url)
      .pipe(
        map((res: Response) => {
          //console.log(res);
          return res;
        })
      )
      .toPromise();
  }

  async markDocumentAsPaid(data): Promise<any> {
    return await this.http.post<any>(this.baseUrl, data).toPromise();
  }

  downloadPDF(url): any {
    return this.http.get(url, { responseType: "blob" }).pipe(
      map((res) => {
        console.log(res);
        let report = new Blob([res], { type: "application/pdf" });
        return report;
      })
    );
  }

  async getDocumentSubscribers(invoiceId: string): Promise<any> {
    let url = `${this.baseUrl}/${invoiceId}/subscribers`;

    return this.http
      .get(url)
      .pipe(
        map((res: Response) => {
          return res["data"];
        })
      )
      .toPromise();
  }

  async shareDocument(data): Promise<any> {
    let url = `${this.baseUrl}/share`;

    return await this.http.post<any>(url, data).toPromise();
  }
}
