import { Component, OnInit, Inject } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";

//Created
import { CurrentUserService } from "../../services/current-user.service";
import { ToastService } from "../../services/toast.service";
import { ToastType } from "../../core/enums";
import { AuthService } from "../../services/auth.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { TokenManager } from "../../services/app-auth/tokenManager";

@Component({
  selector: "main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.css"],
})
export class MainNavComponent implements OnInit {
  private isRoot: boolean;

  aduptod = null as string;
  empty = false;
  buttonShow = false;
  idShow = false;
  plativooId = null as string;
  currentUser = null as any;
  isMobile: boolean;
  showAdminPanelLink = false as boolean;

  constructor(
    @Inject("ADMIN_PANEL_URL") private adminPanelUrl: string,
    private location: Location,
    private router: Router,
    private currentUserService: CurrentUserService,
    private toastService: ToastService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private deviceService: DeviceDetectorService,
    private tokenManager: TokenManager
  ) {}

  gT = (key: string | Array<string>, interpolateParams?: Object) =>
    this.translationService.getTranslation(key, interpolateParams);

  ngOnInit(): void {

    this.tokenManager.token().subscribe((token) => {
      // if (changed) {
      this.currentUser = this.authService.getCurrentUser();
      //console.log(this.currentUser);

      this.changeButtonVisability();
      // }
    });

    this.currentUser = this.authService.getCurrentUser();
    this.isMobile = this.deviceService.isMobile();

    if (this.currentUser.role !== "Invoice") {
      this.showAdminPanelLink = true;
    }

    this.changeButtonVisability();
  }

  viewProfile(){
    this.router.navigateByUrl('/profile');
  }

  Logout() {
    this.authService.signout();
  }

  public changeButtonVisability() {
    this.aduptod = this.currentUser.aduptod;

    if (this.currentUser.plativooId !== undefined) {
      this.plativooId = this.currentUser.plativooId;
    }

    if (this.plativooId == "" && this.aduptod == "False") {
      this.empty = true;
    } else if (this.plativooId == "" && this.aduptod == "True") {
      this.empty = false;
      this.buttonShow = true;
    } else if (this.plativooId != "" && this.aduptod == "True") {
      this.idShow = true;
      this.buttonShow = false;
    }
  }

  public async generatePlativooId() {
    try {
      let result = await this.currentUserService.generatePlativooId();
      this.plativooId = result.data.PlativooId;
      //We refresh the token and call ng on init to display new generated plativoo id
      await this.authService.refreshToken();
      this.changeButtonVisability();
      this.toastService.newToast(
        this.gT("mainNavComponent.success"),
        this.gT("mainNavComponent.successMessage"),
        ToastType.Success
      );
    } catch (error) {
      this.toastService.newToast(
        this.gT("mainNavComponent.error"),
        this.gT("mainNavComponent.errorMessage"),
        ToastType.Error
      );
    }
  }
}
