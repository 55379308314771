import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit {

  documentPreviewHtml;
  loading = false as boolean;
  documentid;
  document;
  showPreviewButton = true;

  constructor(private documentService: DocumentService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private location: Location) { }

  async ngOnInit() {
    this.loading = true;
    this.documentid = this.route.snapshot.paramMap.get("documentid");

    this.documentService.getDocumentTemplatePreview(this.documentid)
      .subscribe((html) => {
        this.documentPreviewHtml = this.sanitizer.bypassSecurityTrustHtml(html);
        this.loading = false;
      });

    await this.getDocument();
  }

  async getDocument() {
    await this.documentService
      .getDocumentById(this.documentid)
      .then(async (document) => {
        this.document = document;
        //console.log("doc:" + document);
        this.loading = false;
      });
  }
  goBack() {
    this.location.back();
  }
}
