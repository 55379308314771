import { Injectable, Injector } from "@angular/core";
//import { AuthService } from './auth/auth.service';
//import { CordovaService } from './cordova.service';
//import { NotificationsService } from 'angular2-notifications';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
//import { ErrorDialogComponent } from '../components/error-dialog-modal/error-dialog-modal.component';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { EMPTY } from "rxjs";
import { AuthService } from "./auth.service";
import { CordovaService } from "./cordova.service";
import { TokenManager } from './app-auth/tokenManager';

//https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  message: string;
  status: number;

  constructor(private authService: AuthService, private router: Router, private tokenManager: TokenManager) {}

  intercept(request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.tokenManager.tokenResponse.accessToken;
    //console.log(accessToken);


    if (!accessToken) {
      return next.handle(request) as any;
    }

    const token = `Bearer ${accessToken}`;

    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    const contentType = request.headers.get("Content-Type");
    if (contentType == undefined || contentType == null) {
      headers = headers.append("Content-Type", "application/json");
    }
    headers = headers.append("Api-Version", "0.0.2");

    const authReq = request.clone({ headers: headers });

    return next.handle(authReq) as any;
  }
}
