import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Created
import * as validators from '../../functions/custom-validators';
import { ToastService } from '../../services/toast.service';
import { ToastType } from '../../core/enums';
import { CurrentUserService } from '../../services/current-user.service';
import { DocumentService } from '../../services/document.service';
import { AppTranslationService } from '../../services/app-translation.service';

@Component({
  selector: 'app-share-document',
  templateUrl: './share-document.component.html',
  styleUrls: ['./share-document.component.scss']
})
export class ShareDocumentComponent implements OnInit {

  userUid: string;
  users = [] as any;
  documentId: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private toastService: ToastService,
              private currentUserService: CurrentUserService,
              private documentService: DocumentService,
              private translationService: AppTranslationService) { }

  gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

  ngOnInit() {
    this.documentId = this.route.snapshot.paramMap.get("documentid");
  }

  async searchUsers() {

    let email = validators.emailModelValidator(this.userUid);
    let plativooID = validators.PlativooIDModelValidator(this.userUid);

    if (!email && !plativooID) {
      this.toastService.newToast(this.gT('shareDocumentComponent.error'), this.gT('shareDocumentComponent.correctFormat'), ToastType.Error);
      return;
    }

    this.users = await this.currentUserService.searchUsersByUid(this.userUid);

    if (this.users.length === 0) {
      this.toastService.newToast(this.gT('shareDocumentComponent.info'), this.gT('shareDocumentComponent.noResults'), ToastType.Info);
    }
  }

  async shareDocument(userId) {
    //console.log('userId:'+ userId + ' documentId:' + this.documentId);

    let data = {
      'userId': userId,
      'documentId': this.documentId
    }

    let result = await this.documentService.shareDocument(data);

    //Result 0 je ok
    if (result.status == 0) {
      this.toastService.newToast(this.gT('shareDocumentComponent.success'), result.message, ToastType.Success);
      this.router.navigateByUrl('/document/' + this.documentId);
    }

    //Result 7 je not accepted
    if (result.status == 7) {
      this.toastService.newToast(this.gT('shareDocumentComponent.error'), result.message, ToastType.Error);
      return;
    }

  }


}
