import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: '[document-type-message]',
  templateUrl: './document-type-message.component.html',
  styleUrls: ['./document-type-message.component.scss']
})
export class DocumentTypeMessageComponent implements OnInit {

  user: any;

  constructor( private router: Router ) { }

  ngOnInit() {  }

 



}
